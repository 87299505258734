import React from 'react';

class NotificationsWidget extends React.Component {


	render() {
		const newsComponents = this.props.news.map((item) => {
			return (
				<div key={item.title} className="dropdown-item"><i className="fa fa-newspaper-o"></i> <strong>{item.title}</strong>
					{/* <Link to={'/'} className='dropdown-item'><i className="fa fa-newspaper-o"></i> <strong>{item.title}  -  {item.update_date.toDateString()}</strong> */}
					<div dangerouslySetInnerHTML={{__html: item.description}} />
					{/* </Link> */} 
				</div>
			);
		});



		return (
			<div>
				{newsComponents}
			</div>
		)
	}
}

export default NotificationsWidget;
