import React from 'react';
import ContentLoader from 'react-content-loader'


class ContainerLoading extends React.Component {

	render() {
		return (
			<div className="container pt-5">
				<ContentLoader />
				<ContentLoader />
			</div>
		);
	}
}


export default ContainerLoading;
