import React, { Component } from 'react';
import { Client } from '../../Client';
import Spinner from '../Spinner/Spinner';
import { Link } from 'react-router-dom';
import UIFormFieldText from '../UIForms/UIFormFieldText';

var config = require('../../config');

// Lead form defaults
let reldeskAcountUserId = '5a54daf7e1cd5b550595f385';		// For inserting into the Reldesk-reldesk account(!)

class SignupDemoForm extends Component {
	state = {
		inProgress: false,
		signupComplete: false,
		fields: {
			email: '',
			firstname: '',
			lastname: '',
			company: '',
			telephone: '',
		},
		lead_property: '',
		lead_campaign: '',
		lead_source: '',
		tags: '',
		property: '',
		hasErrors: false,
		formErrors: {
			general: '',
			email: '',
			firstname: '',
			lastname: '',
			company: '',
			telephone: '',
		},
	};
	componentDidMount() {
		// console.log("Props", this.props);
		if (this.props.match.params.source == 'xero') {
			this.setState({ lead_property: 'Xero Demo Form', lead_campaign: 'Partners Integration',  lead_source: 'Xero App Store', tags: 'demo request,xero,xero integration,integration lead'});
			this.loadXeroSSOData(this.props.location.search);
		}
		else {
			this.setState({ lead_property: 'App Demo Form', lead_campaign: '',  lead_source: '', tags: 'demo request'});
		}
	}

	loadXeroSSOData = (queryParams) => {
		Client.get('/api/crm/financial/xero/auth/sso-callback' + queryParams, (res) => {
			// console.log(res);
			if (res.success) {
				this.setState({
					fields: { firstname: res.firstname, lastname: res.lastname, email: res.email }
				});
			}
		});
	}




	handleSuccess(res) {
		// console.log("Success result", res);
		// if (this.props.onSuccess) this.props.onSuccess(tempPlans[this.state.plan]);
	}


	apiSaveLead = () => {
		var data = {	apikey: reldeskAcountUserId,
						firstname: this.state.fields.firstname,
						lastname: this.state.fields.lastname,
						company_name: this.state.fields.company,
						email: this.state.fields.email,
						telephone: this.state.fields.telephone,
						source: this.state.lead_source,
						campaign: this.state.lead_campaign,
						property: this.state.lead_property,
						tags: this.state.tags,
					}
		Client.post('/api/public/contact/add', data, (res) => {
			this.setState({ inProgress: false, signupComplete: true });
			if (res.success) this.handleSuccess(res);
		}).catch((err) => {
			// this.setState({ inProgress: false, hasErrors: true, formErrors: formErrors.xxx });
			this.setState({ inProgress: false });
		});
		// console.log(data);
	}


	performRegistration = (e) => {
		e.preventDefault();
		this.setState({ inProgress: true, hasErrors: false, formErrors: {} });

		var hasErrors = false;
		var formErrors = {}

		if (!this.state.fields.telephone) { hasErrors = true; formErrors.telephone = "Please enter a telephone"; }
		if (!this.state.fields.firstname) { hasErrors = true; formErrors.firstname = "Please enter your firstname"}
		if (!this.state.fields.lastname) { hasErrors = true; formErrors.lastname = "Please enter your lastname"}
		if (!this.state.fields.company) { hasErrors = true; formErrors.company = "Please enter your company"}
		if (!this.state.fields.email) { hasErrors = true; formErrors.email = "Please enter your email"}

		this.setState({ hasErrors: hasErrors, formErrors: formErrors });

		if (!hasErrors) this.apiSaveLead();
		else this.setState({ inProgress: false });
	}


	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	handleUIFieldChange = (name, val) => {
		const fields = this.state.fields;
		fields[name] = val;
		this.setState({ fields });
	}





	render() {
		if (this.state.signupComplete) {
			return (
				<div className="text-center pt-5 pb-5">
					<span class="fa fa-thumbs-up fa-2x pb-4"></span>
					<h4><strong>Thank You</strong></h4>
					<h6>We'll be in touch shortly to organise your demo.</h6>
					<h6>In the meantime, check out <a href="https://www.reldesk.com/">the website</a> to find out more about Reldesk.</h6>
				</div>
			)
		}
		else return (
			<div>
				<h3 className="text-center">Signup for a Demo Now</h3>

				<form onSubmit={this.performRegistration} className="checkout-form">
					<div className="row">
						<div className="col-md-12">
							<h3 className="text-center pb-1">{this.props.title}</h3>
						</div>
					</div>
					{ this.state.hasErrors ? (
						<div className="row">
							<div className="col-md-10 offset-md-1">
								<div className="bd-callout bd-callout-danger">
									<h5>There were errors submitting your request</h5>
									<p>Correct the issues listed below to continue</p>
								</div>
							</div>
						</div>
					) : ('') }

					{ this.state.formErrors.general ? ( <div className="text-center"><span className="text-danger">{this.state.formErrors.general}</span></div>) : ('')}

					<div className="row pt-2">
						<div className="col-md-4 offset-md-2">
							<UIFormFieldText id="firstname" label="First Name" placeholder='' name="firstname" value={this.state.fields.firstname} onChange={this.handleUIFieldChange}/>
							{ this.state.formErrors.firstname ? ( <span className="text-danger">{this.state.formErrors.firstname}</span>) : ('')}
						</div>
						<div className="col-md-4">
							<UIFormFieldText id="lastname" label="Last Name" placeholder='' name="lastname" value={this.state.fields.lastname} onChange={this.handleUIFieldChange}/>
							{ this.state.formErrors.lastname ? ( <span className="text-danger">{this.state.formErrors.lastname}</span>) : ('')}
						</div>
					</div>

					<div className="row pt-2">
						<div className="col-md-4 offset-md-2">
							<UIFormFieldText id="email" label="Work Email" type="email" placeholder="you@company.com" name="email" value={this.state.fields.email} onChange={this.handleUIFieldChange}/>
							{ this.state.formErrors.email ? ( <span className="text-danger">{this.state.formErrors.email}</span>) : ('')}
						</div>
						<div className="col-md-4">
							<UIFormFieldText id="telephone" label="Telephone" placeholder='' name="telephone" value={this.state.fields.telephone} onChange={this.handleUIFieldChange}/>
							{ this.state.formErrors.telephone ? ( <span className="text-danger">{this.state.formErrors.telephone}</span>) : ('')}
						</div>
					</div>
					<div className="row pt-2">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputCompany" className="col-form-label">Company / Business Name</label>
							<input type="text" name="company" className="form-control" id="inputCompany" placeholder="" value={this.state.fields.company} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.company ? ( <span className="text-danger">{this.state.formErrors.company}</span>) : ('')}
						</div>
					</div>


					<div className="row">
						<div className="col-md-8 offset-md-2">
							<div className="text-center">
								<div className="col-md-8 offset-md-2 mt-5 mb-4">
									{ this.state.inProgress ? (<Spinner type="small"/>) : (<button>Signup</button>) }
								</div>
							</div>
						</div>
					</div>

				</form>
			</div>
		);
		
	}
}

export default SignupDemoForm;

