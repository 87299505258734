import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Client } from '../../Client';
// import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import './Login.css';


class OneTimeToken extends Component {
	state = {
		loginInProgress: false,
		shouldRedirect: false,
	    fields: {
			token: '',
	    },
		hasErrors: false,
		formError: ''
	};


	componentDidMount() {
		const token = this.props.match.params.token || '';
		this.setState({ fields: { token } });
	}


	performLogin = (e) => {
		e.preventDefault();
		this.setState({ loginInProgress: true });
		Client.loginToken(this.state.fields.token).then((res) => {
			console.log(this.state.fields.token);
			var errMessage = '';
			if (res.success === false) {
				if (res.code === 10040) errMessage = <div><h5>Incorrect Token</h5><p>Check it's correct and try again</p></div>;
				else if (res.code === 10041) errMessage = <div><h5>Account not Activated</h5><p><a href="/account/activation">Resend your Activation Code</a></p></div>;
				else if (res.code === 10042) errMessage = <div><h5>Account doesn't exist</h5><p>Don't have any account? <a href="/register">Signup now</a></p></div>;
				else errMessage = <h5>{res.message}</h5>;
				this.setState({ hasErrors: true, formError: errMessage, loginInProgress: false });			
			}
			else this.setState({ shouldRedirect: true })
		}).catch((err) => {
			if (err.status === 'Unauthorized') alert("Error logging in. Please check details");
			this.setState({ loginInProgress: false });			
		});
	};

	redirectPath = () => {
		const locationState = this.props.location.state;
		const pathname = (
			locationState && locationState.from && locationState.from.pathname
		);
		return pathname || '/dashboard';
	};


	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	};


	render() {
		if (this.state.shouldRedirect) {
			return (
				<Redirect to={this.redirectPath()} />
			);
		} else {
			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						
						{ this.state.loginInProgress ? (<Spinner/>) : ('') }

						<div className="row">
							<div className="col-md-6 offset-md-3 pt-5">

								<div className="card pt-3">
									<div className="card-block">
										<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}><strong>Login</strong> to Reldesk</h4>

										<form onSubmit={this.performLogin}>
											{ this.state.hasErrors ? (
												<div className="row">
													<div className="col-md-10 offset-md-1">
														<div className="bd-callout bd-callout-danger">
															{this.state.formError}
														</div>
													</div>
												</div>
											) : ('') }
											<div className="row">
												<div className="col-md-8 offset-md-2">
													<label htmlFor="inputToken" className="col-form-label">Unique Code</label>
													<input type="text" name="token" className="form-control" id="inputToken" placeholder="" value={this.state.fields.token} onChange={this.handleFieldChange}/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-8 offset-md-2" style={{marginTop: '20px', marginBottom: '20px'}}>
													<button type="submit" className="form-control btn btn-primary">
														Login
													</button>
												</div>
											</div>

										</form>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default OneTimeToken;
