// var config = require('../../config');
import config from '../../config';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { Client } from '../../Client';
import { StripeProvider, Elements } from 'react-stripe-elements';
import RegisterSignupForm from '../Billing/RegisterSignupForm';

import Package from '../Billing/Package';
import packages from '../../data/package-lookups.json';
import queryString from 'query-string';

import './Registration.css';

const taxPercent = 20;

class Registration extends Component {
	state = {
		package: this.props.match.params.package_id || '',
		period: this.props.match.params.package_period || 'monthly',
		cost: 0,
		plan: null,
		plan_period_id: '',
		inProgress: false,
		registerConfirmation: false,
		fields: {
			email: '',
			password: '',
			firstname: '',
			lastname: '',
			company: '',
		},
		hasErrors: false,
		formErrors: {
			email: '',
			password: ''
		},
		coupon: '',
	}


	redirectPath = () => {
		const locationState = this.props.location.state;
		const pathname = (
			locationState && locationState.from && locationState.from.pathname
		);
		console.log("Redirect", pathname);
		return pathname || '/dashboard';
	}

	lookupCoupon = (coupon) => {
		// console.log("Lookup coupon");
		Client.get('/api/billing/coupon/' + coupon, (response) => {
			if (response.success && response.coupon) this.setState({ coupon: response.coupon.id })
			else this.setState({ coupon: '' })
		});
	}

	componentWillMount() {
		const query = queryString.parse(this.props.location.search);
		// if (query.coupon) this.setState({ coupon: query.coupon });

		if (this.state.package in packages && packages[this.state.package].active === true) {
			this.lookupCoupon(query.coupon);
			const plan = packages[this.state.package];
			var plan_period_id;
			var cost; 
			if (this.state.period === 'yearly') {
				plan_period_id = packages[this.state.package].plan_yearly_id;
				cost = (plan.price_py * 12)
			}
			else {
				plan_period_id = packages[this.state.package].plan_monthly_id;
				cost = plan.price_pm;
			}
			console.log("Cost: ", cost);
			console.log("Tax Percent: ", taxPercent);
			console.log("Percent: ", (taxPercent / 100));
			const tax = cost * (taxPercent / 100);
			console.log("Tax: ", tax);
			cost += tax;
			console.log("Cost inc Tax: ", cost);
			cost = parseFloat(cost).toFixed(2);
			console.log("Cost Rounded: ", cost);
			this.setState({ plan: plan, plan_period_id: plan_period_id, cost: cost });
		}
	}

	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	handleSuccess = () => {
		this.setState({ registerConfirmation: true })
	}

	showPeriod = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	showTotal = () => {
		// console.log("TOtal", this.state.plan);
		return '£' + this.state.cost;

	}


	render() {
		if (this.state.registerConfirmation) {
			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						<div className="row">
							<div className="col-md-6 offset-md-3">
								<div className="card">
									<div className="card-block">
										<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Account Created!</h4>
	
										<div className="row">
											<div className="col-md-10 offset-md-1">
												<div className="bd-callout bd-callout-info">
													<h5>Check your activation link</h5>
													<p>Check your email and click on the link to <strong>activate your account</strong>.</p>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			var selectedPackage;
			if (this.state.plan) selectedPackage = <Package title={this.state.plan.title} display="minimal" image={this.state.plan.image}></Package>
			else return <Redirect to='/signup'/>;

			return (
				<div className="bgimage">
					<div className="container page-dashboard">
						
						{ this.state.inProgress ? (<Spinner/>) : ('') }

						<div className="row">
							<div className="col-lg-6 offset-lg-3">

								<div className="card">
									<div className="card-block">
										<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}><strong>Create</strong> your Reldesk Account</h4>

										{ selectedPackage }
										<div className="text-center"><span className="font-weight-bold">{this.showTotal()}</span> { this.showPeriod(this.state.period) }</div>
										<div className="text-center">*includes VAT</div>

										<StripeProvider apiKey={config.services.stripe.key}>
											<Elements>
												<RegisterSignupForm plan={this.state.plan.id} plan_period_id={this.state.plan_period_id} coupon={this.state.coupon} onSuccess={this.handleSuccess}/>
											</Elements>
										</StripeProvider>
										
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Registration;
