import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Client } from '../../Client';
import NotificationsMenuItem from './NotificationsMenuItem';

import logo from '../../../assets/img/logo.svg';
import Avatar from '../Avatar/Avatar';
import './TopBar.css';
import { Trans } from "react-i18next";
// import i18n from "i18next";

// const changeLanguage = lng => { i18n.changeLanguage(lng) }
import iconOrgs from '../../../assets/img/icons/main-building-simple.svg';
import iconContacts from '../../../assets/img/icons/main-people-simple.svg';
import iconOpps from '../../../assets/img/icons/main-handshake.svg';
import iconTasks from '../../../assets/img/icons/main-tasks.svg';
import iconCal from '../../../assets/img/icons/main-calendar.svg';
import iconSubscription from '../../../assets/img/icons/main-card.svg';
import iconReport from '../../../assets/img/icons/main-report.svg';
import iconPhone from '../../../assets/img/icons/main-phone.svg';


class TopBar extends React.Component {
	
	componentDidMount() {
		// changeLanguage("encharity");
	}

	render() {
		// console.log("Client", Client.hasAdditionalFeature('campaigns'));
		const firstname = Client.getFirstname();
		const lastname = Client.getLastname();

		return (
			<nav className="navbar navbar-toggleable-md navbar-inverse fixed-top bg-inverse">
				<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<Link to={'/dashboard'} className='navbar-brand'><img src={logo} style={{width: 160 + 'px'}} alt='Reldesk'/></Link>
				
				{ Client.isLoggedIn() ? (
				<div className="collapse navbar-collapse" id="navbarCollapse">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item">
							<NavLink to={'/contacts'} className='nav-link' activeClassName='active'><img src={iconContacts} style={{width: '30px', marginTop: -2}} alt=''/> Contacts</NavLink>							
						</li>

						<li className="nav-item">
							<NavLink to={'/organisations'} className='nav-link' activeClassName='active'><img src={iconOrgs} style={{width: '30px', marginTop: -2}} alt=''/> Organisations</NavLink>
						</li>

{/* 
						{ Client.hasRole(['accelerate']) &&
						<li className="nav-item">
							<NavLink to={'/email'} className='nav-link' activeClassName='active'><i className="fa fa-envelope"></i> Messages</NavLink>
						</li>
						}
*/}
						{/*
						<li className="nav-item">
							<NavLink to={'/calls'} className='nav-link' activeClassName='active'><i className="fa fa-phone"></i> Calls</NavLink>
						</li>
						*/}
						<li className="nav-item">
							<NavLink to={'/opportunities'} className='nav-link' activeClassName='active'><img src={iconOpps} style={{width: '30px', marginTop: -2}} alt=''/> <Trans>Opportunities</Trans></NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={'/tasks'} className='nav-link' activeClassName='active'><img src={iconTasks} style={{width: '30px', marginTop: -2}} alt=''/> Tasks</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to={'/appointments'} className='nav-link' activeClassName='active'><img src={iconCal} style={{width: '30px', marginTop: -2}} alt=''/> Appointments</NavLink>
						</li>

						{/* (Client.hasAdditionalFeature('projects')) &&
						<li className="nav-item">
							<NavLink to={'/projects'} className='nav-link' activeClassName='active'><i className="fa fa-tasks"></i> Projects</NavLink>
						</li>
						*/}

						{ (Client.hasAdditionalFeature('reporting')) &&
						<li className="nav-item">
							<NavLink to={'/reporting'} className='nav-link' activeClassName='active'><img src={iconReport} style={{width: '30px', marginTop: -2}} alt=''/> Reporting</NavLink>
						</li>
						}


						{ (Client.hasAdditionalFeature('subscriptions')) &&
						<li className="nav-item">
							<NavLink to={'/subscriptions'} className='nav-link' activeClassName='active'><img src={iconSubscription} style={{width: '30px', marginTop: -2}} alt=''/> <Trans>Subscriptions</Trans></NavLink>
						</li>
						}
						{ (Client.hasAdditionalFeature('phone')) &&
						<li className="nav-item">
							<NavLink to={'/telephone'} className='nav-link' activeClassName='active'><img src={iconPhone} style={{width: '30px', marginTop: -2}} alt=''/> Phone</NavLink>
						</li>
						}
					</ul>
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<a className="nav-link icon-help" href="https://help.reldesk.com/" target="_blank" rel="noopener noreferrer">
								<i className="fa fa-question-circle-o"></i>
							</a>
						</li>
						<li className="nav-item dropdown">
							<NotificationsMenuItem />
						</li>
						<li className="nav-item dropdown">
							<a className="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown">
								<Avatar firstname={firstname} lastname={lastname} image={''} size={'small'} />
							</a>
							<div className="dropdown-menu dropdown-menu-right">
								<Link to={'/profile/settings'} className='dropdown-item'><i className="fa fa-user-circle-o"></i> Profile Settings</Link>
								<Link to={'/settings/account'} className='dropdown-item'><i className="fa fa-gears"></i> Account Settings</Link>
								{(Client.hasAdditionalFeature('automations')) &&
									<Link to={'/automations'} className='dropdown-item'><i className="fa fa-gears"></i> Automations</Link>
								}
								{(Client.hasAdditionalFeature('forms')) &&
									<Link to={'/forms'} className='dropdown-item'><i className="fa fa-gears"></i> Web Forms</Link>
								}
								<Link to={'/logout'} className='dropdown-item'><i className="fa fa-power-off"></i> Logout</Link>
							</div>
						</li>
					</ul>		
				</div>
				) : (
				<div className="collapse navbar-collapse" id="navbarCollapse">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link className='nav-link' to='/login'>Login</Link>
						</li>
						<li className="nav-item">
							<Link className='nav-link' to='/signupdemo/'>Signup</Link>
							{/* <a href="https://www.reldesk.com/signup/" className='nav-link'>Signup</a> */}
						{/* 
							<Link className='nav-link' to='/signup'>Signup</Link>
						*/}
						</li>
					</ul>
				</div>
				)}
			</nav>
		);
	}
}




export default TopBar;
