// export function getSafe(fn) {
// 	try {
// 		return fn();
// 	} catch (e) {
// 		return undefined;
// 	}
// }
const LOCAL_STORAGE_KEY = 'reldesk-token';


export function getSafe(fn, defaultVal) {
	try { return fn(); }
	catch (e) { return defaultVal; }
}
// // use it like this
// getSafe(() => obj.a.lot.of.properties);

// // or add an optional default value
// getSafe(() => obj.a.lot.of.properties, 'nothing');




export function authHeader() {
	// return authorization header with jwt token
	const auth = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

	if (auth && auth.token) {
		return {
			'Authorization': 'Bearer ' + auth.token,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		};
	}
	else return { 'Accept': 'application/json' };
}



export function addhttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}

