import React, { Component } from 'react';
import PasswordResetEmail from '../Login/PasswordResetEmail';
import PasswordReset from '../Login/PasswordReset';


class PasswordResetContainer extends Component {

	render() {
		if (this.props.match && this.props.match.params.token) {		
			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						<PasswordReset token={this.props.match.params.token} />
					</div>
				</div>
			);
		}
		else {
			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						<PasswordResetEmail />	
					</div>
				</div>
			);
		}
	}
}

export default PasswordResetContainer;


