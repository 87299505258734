import React, { Component } from 'react';
import config from '../../config';
// import { Redirect } from 'react-router-dom';
// import Switch from "react-switch";

// import Package from '../Billing/Package';
// import plans from '../../data/package-lookups.json';
// import renderHTML from 'react-render-html';
import { StripeProvider, Elements } from 'react-stripe-elements';
import NewSignupForm from '../Billing/NewSignupForm';



class SignupBContainer extends Component {


	componentDidMount() {
	}

	render() {

		return (
			<div className="bgimage">
				<div className="container page-dashboard style={{ textAlign: 'center' }}">
					
					<div className="row">
						<div className="col-md-12">

							<div className="card">
								<div className="card-block mt-3">
									<h2 className="text-center" style={{marginBottom: 30, fontWeight: 'bold'}}>Ready to <span className="g-color--primary">save time</span> and make <span className="g-color--primary">building relationships</span> easier?</h2>


									<div className="container">
										<div className="row packages">

											<div className="col-md-8 offset-md-2">

												<StripeProvider apiKey={config.services.stripe.key}>
													<Elements>
														<NewSignupForm plan={1} onSuccess={this.handleSuccess}/>
													</Elements>
												</StripeProvider>

											</div>






										</div>

										<div className="row mt-4">
											<div className="col-md-12 text-center">
												<p>
													All prices <strong>exclude VAT</strong>
												</p>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default SignupBContainer;
