import React from 'react';

// Defaults
export const teamPreferences = {
	opportunity_stages: [
		'Prospect',
		'Qualify',
		'Proposal',
		'Negotiate',
		'Closed'
	],

	contact_types: [
		"Suspect",
		"Target",
		"Lead", 
		"Prospect", 
		"Client", 
		"Ex Client", 
		"Partner", 
		"Supplier"
		],

	organisation_types: [
		"Client",
		"Supplier"
		],
}

export const TeamPreferenceContext = React.createContext(
	teamPreferences
);

