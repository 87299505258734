import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import { Client } from '../../Client';
import Switch from "react-switch";

import Package from '../Billing/Package';
import plans from '../../data/package-lookups.json';
import renderHTML from 'react-render-html';


class SignupContainer extends Component {
	state = {
		redirect: false,
		redirectPath: '',
		checkedYearly: true,
	}

	handleSelectPackage = (plan) => {
		var period;
		if (this.state.checkedYearly === true) period = 'yearly';
		else period = 'monthly';
		if (plan) this.setState({ redirect: true, redirectPath: '/' + plan + '/' + period});
	}


	handleChange = (checkedYearly) => {
		this.setState({ checkedYearly });
	}

	componentDidMount() {
	}

	render() {
		if (this.state.redirect) return (<Redirect push to={'/register' + this.state.redirectPath} />)

		var displayPlans = [];
		for (var plan in plans) {
			if (plans.hasOwnProperty(plan)) {
				var price, cta, priceStatement;
				if (plans[plan].price_py === -1) {
					price = "Coming soon";
					cta = <div className="mt-3"><p className="btn btn-secondary disabled">Coming soon</p></div>
				}
				else {
					if (this.state.checkedYearly) {
						price = plans[plan].price_py;
						priceStatement = "<span className='text-muted'>per month, billed annually</span>"
					}
					else {
						price = plans[plan].price_pm;
						priceStatement = "<span className='text-muted'>per month</span>"
					}
					cta = <div className="mt-3"><p className="btn btn-primary">Signup</p></div>
				}
				// const priceStatement = plans[plan].price_html;


				if (plans[plan].featured === true) displayPlans.push(
					<div key={ plan } className="col-md-4">
						<Package
							plan={ plan }
							title={ plans[plan].title }
							image={ plans[plan].image }
							price={ price }
							currency="GBP"
							active={true}
							onClick={(plan) => { this.handleSelectPackage(plan) }}
							cta={ cta }
							priceStatement={ priceStatement }
							>
							{ renderHTML(plans[plan].features_html) }
						</Package>
					</div>
				)
			}
		}


		return (
			<div className="bgimage">
				<div className="container page-dashboard style={{ textAlign: 'center' }}">
					
					<div className="row">
						<div className="col-md-12">

							<div className="card">
								<div className="card-block mt-3">
									<h2 className="text-center" style={{marginBottom: 30, fontWeight: 'bold'}}>Ready to <span className="g-color--primary">save time</span> and make <span className="g-color--primary">building relationships</span> easier?</h2>
									<h3 className="text-center" style={{marginBottom: 30}}>Choose Your Plan</h3>
									<div className="text-center" style={{marginBottom: 30}}>
										<label htmlFor="switch-period">
											<span className="switch-period-text">Monthly</span>
												<Switch
													onChange={this.handleChange}
													checked={this.state.checkedYearly}
													id="switch-period"
													uncheckedIcon={false}
													checkedIcon={false}
													handleDiameter={28}
													offColor="#00b1c8"
													onColor="#00b1c8"
													height={40}
													width={70}
													className="react-switch"
												/>
											<span className="switch-period-text pos-rel">Yearly <span className="pos-abs" style={{top: -4, right: -110}}><span className="badge badge-success">2 months free</span></span></span>
										</label>
									</div>

									<div className="container">
										<div className="row packages">
											{ displayPlans }
										</div>

										<div className="row mt-4">
											<div className="col-md-12 text-center">
												<p>
													All prices <strong>exclude VAT</strong>
												</p>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default SignupContainer;
