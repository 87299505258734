import React, { Component } from 'react';
import config from '../../config';
import queryString from 'query-string';
import CustomSignupForm from '../Billing/CustomSignupForm';


class NewSignupContainer extends Component {
	state = {
		coupon: '',
	}

	componentDidMount() {
		const query = queryString.parse(this.props.location.search);
		if (query.coupon) this.setState({ coupon: query.coupon });
	}


	handleSuccess = (planData) => {
		var redirectLink;

		if (planData.request_payment) redirectLink = planData.plan_payment_link;
		else redirectLink = config.domain + '/account/activation';
		// console.log("Payment", planData);
		// console.log(redirectLink);
		// console.log(this.props);
		window.location.href = redirectLink; 
	}


	render() {

		return (
			<div className="">
				<div className="container page-dashboard">
					
					<div className="row">
						<div className="col-md-12">

							<div className="card">
								<div className="card-block mt-3">
									<h2 className="text-center" style={{marginBottom: 30, fontWeight: 'bold'}}>Ready to <span className="g-color--primary">save time</span> and <span className="g-color--primary">build business relationships</span>?</h2>


									<div className="container">
										<div className="row packages">

											<div className="col-md-8 offset-md-2">

												<CustomSignupForm plan_id={this.props.match.params.plan_id} onSuccess={this.handleSuccess} coupon={this.state.coupon}/>

											</div>

										</div>


									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default NewSignupContainer;
