import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
	// .use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en: {
				translations: {
					// "First Line": "Welcome to React and react-i18next",
					// welcome: "<strong>Hello World</strong>",
					"Opportunities": "Opportunities",
				}
			},
			encharity: {
				translations: {
					// "Opportunities": "Grants",
					"Subscriptions": "Donations",
				}
			},
			de: {
				translations: {
					"Opportunities": "Chancen",
				}
			},
		},
		fallbackLng: "en",
		debug: false,

		// have a common namespace used around the full app
		ns: ["translations"],
		defaultNS: "translations",

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
