import React, { Component } from 'react';
import { Client } from '../../Client';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';


class PasswordReset extends Component {
	state = {
		inProgress: false,
	    fields: {
			password: '',
	    },
		hasErrors: false,
		hasMessage: false,
		formError: '',
		formMessage: ''
	};

	resetPassword = (e) => {
		e.preventDefault();
		this.setState({ inProgress: true });
		var errMessage = '';
		var infoMessage = '';

		var data = { token: this.props.token, password: this.state.fields.password }

		Client.post('/api/auth/password/change', data, (res) => {
			console.log(res);
			if (res.success === false) {
				errMessage = <h5>{res.message}</h5>;
				this.setState({ hasErrors: true, formError: errMessage, inProgress: false });
			}
			else {
				infoMessage = <div><h5>Password has been reset</h5><Link to={'/login'}>Go back to Login </Link></div>;
				this.setState({ hasErrors: false, hasMessage: true	, formMessage: infoMessage, inProgress: false });				
			}
		}).catch((err) => {
			if (err.message) errMessage = <div><h5>{err.message}</h5></div>;
			this.setState({ hasErrors: true, hasMessage: true, formError: errMessage, inProgress: false });			
		});
	};


	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	};


	render() {
		return (
			<div>
				{ this.state.inProgress ? (<Spinner/>) : ('') }

				<div className="row">
					<div className="col-md-6 offset-md-3">

						<div className="card">
							<div className="card-block">
								<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Reset <strong>Password</strong></h4>
								<p>Please enter a new password below to reset your account's password</p>
								<form onSubmit={this.resetPassword}>
									{ this.state.hasErrors ? (
										<div className="row">
											<div className="col-md-10 offset-md-1">
												<div className="bd-callout bd-callout-danger">
													{this.state.formError}
												</div>
											</div>
										</div>
									) : ('') }
									{ this.state.hasMessage ? (
										<div className="row">
											<div className="col-md-10 offset-md-1">
												<div className="bd-callout bd-callout-info">
													{this.state.formMessage}
												</div>
											</div>
										</div>
									) : ('') }
									<div className="row">
										<div className="col-md-8 offset-md-2">
											<label htmlFor="inputEmail" className="col-form-label">New Password</label>
											<input type="password" name="password" className="form-control" id="inputPassword" placeholder="" value={this.state.fields.password} onChange={this.handleFieldChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-8 offset-md-2" style={{marginTop: '20px', marginBottom: '20px'}}>
											<button type="submit" className="form-control btn btn-primary">
												Change Password
											</button>
										</div>
									</div>

								</form>

							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default PasswordReset;


