import React, { Component } from 'react';
import { Client } from '../../Client';
import Spinner from '../Spinner/Spinner';
import { Link } from 'react-router-dom';
import './CheckoutForm.css';
var config = require('../../config');

class CustomSignupForm extends Component {
	state = {
		defaultPlan: 'ACCELERATE',
		inProgress: false,
		fields: {
			email: '',
			password: '',
			password2: '',
			firstname: '',
			lastname: '',
			telephone: '',
			company: '',
		},
		hasErrors: false,
		formErrors: {
			general: '',
			email: '',
			password: '',
			password2: '',
			firstname: '',
			lastname: '',
			telephone: '',
			company: '',
		},
	};

	getBillingPlan = async (planId) => {
		const res = await Client.get('/api/billing/plan/' + planId);
		if (res.success) this.setState({ plan: res.results });
	}

	componentDidMount() {
		this.getBillingPlan(this.props.plan_id);
	}


	handleRegistrationSuccess(res) {
		console.log("Success result", res);
		// var redirectLink;

		// if (planData.request_payment) redirectLink = planData.plan_payment_link;
		// else redirectLink = config.domain + '/account/activation';
		// // console.log("Payment", planData);
		// // console.log(redirectLink);
		// // console.log(this.props);
		// window.location.href = redirectLink; 

		if (this.props.onSuccess) this.props.onSuccess(this.state.plan);
	}


	performRegistration = (e) => {
		e.preventDefault();
		this.setState({ inProgress: true, hasErrors: false, formErrors: {} });

		var data = {
			plan_id: this.props.plan_id,
			plan_period: this.state.fields.plan_period,
			email: this.state.fields.email, 
			password: this.state.fields.password,
			firstname: this.state.fields.firstname,
			lastname: this.state.fields.lastname,
			telephone: this.state.fields.telephone,
			company: this.state.fields.company,
			redirectLink: config.domain + '/account/activation',
		}
		const fullname = data.firstname + ' ' + data.lastname;

		var hasErrors = false;
		var formErrors = {}

		if (!this.state.fields.password) { hasErrors = true; formErrors.password = "Please enter a password"; }
		if (this.state.fields.password != this.state.fields.password2) { hasErrors = true; formErrors.password = "Passwords don't match"; }
		if (!this.state.fields.firstname) { hasErrors = true; formErrors.firstname = "Please enter your firstname"}
		if (!this.state.fields.lastname) { hasErrors = true; formErrors.lastname = "Please enter your lastname"}
		if (!this.state.fields.telephone) { hasErrors = true; formErrors.telephone = "Please enter your telephone"}
		if (!this.state.fields.company) { hasErrors = true; formErrors.company = "Please enter your company"}
		if (!this.state.fields.email) { hasErrors = true; formErrors.email = "Please enter your email"}

		this.setState({ hasErrors: hasErrors, formErrors: formErrors, inProgress: false });

		if (!hasErrors) {
			Client.register2(data).then((res) => {
				if (res.success === false) {
					var formErrors = {};
					formErrors[res.field] = res.message;
					this.setState({ hasErrors: true, formErrors: formErrors, inProgress: false });			
				}
				else {
					this.setState({ inProgress: false });
					this.handleRegistrationSuccess(res);
				}
			}).catch((err) => {
				console.log(err);
			// 	if (err.status === 'Bad Request') alert("Error");
				this.setState({ inProgress: false });			
			});
		}

		this.setState({ inProgress: false });

	}


	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}



	renderPrice() {
		var displayPrice = '';
		if (this.state.plan.price_pm == 0 ) {
			displayPrice = <span className="font-weight-bold">Free</span>
		}
		else {
			displayPrice = '£' + this.state.plan.price_pm;
			// displayPrice += ' per user, per month';
			displayPrice += ' per month';
		}

		return (
			<p className="text-center pb-4">{displayPrice}</p>
		);
	}

	renderPaymentInfo = () => {
		if (!this.state.plan.request_payment) return (
			<div>
				<div>No payment required</div>
			</div>			
		);
		else { 
			if (this.state.plan.plan_payment_integration === 'gocardless') return (
				<div>
				<p>
					* All prices <strong>exclude VAT</strong>
				</p>
					<p className="font-weight-bold">Payment will be taken by</p>
					<p>
						<img src="/assets/img/gcdd.png" style={{width:140}}/>
					</p>

					{ this.state.formErrors.card ? ( <span className="text-danger">{this.state.formErrors.card}</span>) : ('')}

					{ this.props.coupon ? (
						<div>
							Coupon code <strong>{ this.props.coupon }</strong> will be applied on signup.
						</div>
					) : ('') }
				</div>
			);
			else {
				return (<div>Invalid payment configuration</div>);
			}
		}
	}


	render() {
		if (!this.state.plan) return(<div className="text-center pt-5 pb-5"><strong>No Signup Plan Selected</strong></div>);
		else return (
			<div>
				{ this.state.inProgress ? (<Spinner/>) : ('') }

				<form onSubmit={this.performRegistration} className="checkout-form">
					<div className="row">
						<div className="col-md-12">
							<h3 className="text-center pb-1">{this.state.plan.label}</h3>
							{ this.renderPrice() }
						</div>
					</div>
					{ this.state.hasErrors ? (
						<div className="row">
							<div className="col-md-10 offset-md-1">
								<div className="bd-callout bd-callout-danger">
									<h5>There were errors creating your account</h5>
									<p>Correct the issues listed below to continue</p>
								</div>
							</div>
						</div>
					) : ('') }

					{ this.state.formErrors.general ? ( <div className="text-center"><span className="text-danger">{this.state.formErrors.general}</span></div>) : ('')}

					<div className="row">
						<div className="col-md-4 offset-md-2">
							<label htmlFor="inputFirstname" className="col-form-label">Firstname</label>
							<input type="text" name="firstname" className="form-control" id="inputFirstname" placeholder="" value={this.state.fields.firstname} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.firstname ? ( <span className="text-danger">{this.state.formErrors.firstname}</span>) : ('')}
						</div>
						<div className="col-md-4">
							<label htmlFor="inputLastname" className="col-form-label">Lastname</label>
							<input type="text" name="lastname" className="form-control" id="inputLastname" placeholder="" value={this.state.fields.lastname} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.lastname ? ( <span className="text-danger">{this.state.formErrors.lastname}</span>) : ('')}
						</div>
					</div>

					<div className="row">
						<div className="col-md-4 offset-md-2">
							<label htmlFor="inputEmail" className="col-form-label">Work Email</label>
							<input type="text" name="email" className="form-control" id="inputEmail" placeholder="you@company.com" value={this.state.fields.email} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.email ? ( <span className="text-danger">{this.state.formErrors.email}</span>) : ('')}
						</div>
						<div className="col-md-4">
							<label htmlFor="inputTelephone" className="col-form-label">Telephone</label>
							<input type="text" name="telephone" className="form-control" id="inputTelephone" placeholder="" value={this.state.fields.telephone} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.telephone ? ( <span className="text-danger">{this.state.formErrors.telephone}</span>) : ('')}
						</div>
					</div>

					<div className="row">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputCompany" className="col-form-label">Company / Business Name</label>
							<input type="text" name="company" className="form-control" id="inputCompany" placeholder="" value={this.state.fields.company} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.company ? ( <span className="text-danger">{this.state.formErrors.company}</span>) : ('')}
						</div>
					</div>
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputPassword" className="col-form-label">Password</label>
							<input type="password" name="password" className="form-control" id="inputPassword" placeholder="" value={this.state.fields.password} onChange={this.handleFieldChange}/>
							<p className="small">Minimum 8 characters and at least 1 number and 1 symbol</p>
							{ this.state.formErrors.password ? ( <span className="text-danger">{this.state.formErrors.password}</span>) : ('')}
						</div>
					</div>
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputPassword" className="col-form-label">Re-enter Password</label>
							<input type="password" name="password2" className="form-control" id="inputPassword2" placeholder="" value={this.state.fields.password2} onChange={this.handleFieldChange}/>
							<p className="small">Minimum 8 characters and at least 1 number and 1 symbol</p>
							{ this.state.formErrors.password ? ( <span className="text-danger">{this.state.formErrors.password}</span>) : ('')}
						</div>
					</div>


					<div className="row">
						<div className="col-md-8 offset-md-2">
							<div className="text-center">
								{ this.renderPaymentInfo() }

								<div className="col-md-8 offset-md-2" style={{marginTop: '30px', marginBottom: '20px'}}>
									
									{ this.state.inProgress ? (<Spinner/>) : (<button>Signup</button>) }

								</div>
							</div>
						</div>
					</div>


				</form>
			</div>
		);
		
	}
}

export default CustomSignupForm;

