import React from 'react';

class UIFormFieldText extends React.Component {
	// Props:
	//	id, name, label, value, onchange, error, readonly

	componentDidMount() {

	}

	handleChange = (e) => {
		if (this.props.onChange) {
			this.props.onChange(this.props.name, e.target.value);
		}
	};


	render() {
		let icon = '';
		if (this.props.icon) icon = <span><i className={this.props.icon}></i>&nbsp;</span>
		return (
			<div>
				{this.props.label &&
					<label htmlFor={this.props.id} className="col-form-label">{icon}{this.props.label}</label>
				}
				<input type={this.props.type || 'text'} readOnly={this.props.readonly} name={this.props.name} className="form-control" id={this.props.id} value={this.props.value} onChange={this.handleChange} placeholder={this.props.placeholder || ''}/>
				{ this.props.error && <span className="formError">{ this.props.error }</span> }				
			</div>
		);
	}
}




export default UIFormFieldText;

