import React from 'react';

import './UISideNav.css';


class UISideNav extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// visible: this.props.visible || false,
			open: this.props.open || false,
		}
	}


	handleClick = () => {
		this.setState(prev => ({ open: !prev.open }))
	}

	//🎉
	render() {
		if (!this.props.visible) return false;
		const icon = this.state.open ? 'fa-times' : 'fa-bars';
		return (
			<div id="navbar" className={this.state.open ? 'slideIn' : 'slideOut'}>
				{this.props.children}
				<button type="button" onClick={this.handleClick} class={'fa ' + icon}></button>
			</div>
		)
	}


}

export default UISideNav;

