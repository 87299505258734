import React from 'react';
import NotificationsWidget from './NotificationsWidget';
import { Prefs } from '../../lib/prefs';
const { getSafe } = require('../../lib/helper');

class NotificationsMenuItem extends React.Component {

	state = {
		news: [
			{ update_date: new Date('2021-08-11T12:00:00Z'), title: 'Reldesk v0.17.0', description: '<p>Improvements to the Contacts map system and Calendar.</p>' },
			{ update_date: new Date('2021-04-10T12:00:00Z'), title: 'Reldesk v0.16.6', description: '<p>Additional features added to Tasks, Email templates and Financials</p>' },
			{ update_date: new Date('2021-03-01T12:00:00Z'), title: 'Reldesk v0.16.0', description: '<p>More design and interface improvements<br/>Speed improvements and more email integrations</p>' },
			{ update_date: new Date('2021-02-04T12:00:00Z'), title: 'Reldesk v0.15.1', description: '<p>Xero and Quickbooks integration updates<br/>Design and interface improvements</p>' },
			{ update_date: new Date('2021-01-07T12:00:00Z'), title: 'Reldesk v0.12.0', description: '<p>Subscription improvements and new features</p>' },
			{ update_date: new Date('2020-09-28T12:00:00Z'), title: 'Reldesk v0.11.7', description: '<p>New Link custom field, tagging and email improvements<br/>Improved contact custom fields display</p>' },
			{ update_date: new Date('2020-09-11T12:00:00Z'), title: 'Reldesk v0.11.5', description: '<p>Marketing, import and finance integration improvements</p>' },
			{ update_date: new Date('2020-06-24T12:00:00Z'), title: 'Reldesk v0.11.2', description: '<p>Account, email template and data improvements</p>' },
			{ update_date: new Date('2020-05-27T12:00:00Z'), title: 'Reldesk v0.11.1', description: '<p>Additions and improvements to Organisations.<br/>Infrastructure updates for future functionality</p>' },
			{ update_date: new Date('2020-03-10T12:00:00Z'), title: 'Reldesk v0.10.0', description: '<p>Usability improvements, Task and Calendar additions,<br/>Custom Field improvements</p>' },
			{ update_date: new Date('2020-01-22T12:00:00Z'), title: 'Reldesk v0.9.0', description: '<p>Financial improvements, marketing integrations and interface enhancements</p>' },
			{ update_date: new Date('2020-01-13T12:00:00Z'), title: 'Reldesk v0.8.0', description: '<p>Improvements to Tasks, Integrations and our prospecting data tools</p>' },
			{ update_date: new Date('2019-11-25T12:00:00Z'), title: 'Reldesk v0.7.0', description: '<p>Xero partner integration now in beta, along with lots of interface updates</p>' },
			{ update_date: new Date('2019-10-25T12:00:00Z'), title: 'Reldesk v0.6.2', description: '<p>More field updates including Opportunities improvements</p>' },
			{ update_date: new Date('2019-09-17T12:00:00Z'), title: 'Reldesk v0.6.0', description: '<p>Lots of interface and functionality improvements - too many to list!</p>' },
			{ update_date: new Date('2019-07-11T12:00:00Z'), title: 'Reldesk v0.5.5', description: '<p>Improvements to Notes, Activity and Organisations, Data prospecting<br/>tools and further improvements for new features coming soon</p>' },
			{ update_date: new Date('2019-06-19T12:00:00Z'), title: 'Reldesk v0.5.0', description: '<p>Updates to Custom Fields to include Select, Yes/No and<br/>Multi-Select fields on Organisations, Contacts and Opportunities</p>' },
			{ update_date: new Date('2019-05-24T12:00:00Z'), title: 'Reldesk v0.4.0', description: '<p>Team accounts and organisations now in beta!<br/>Huge update to incorporate data sharing across teams.</p>' },
			{ update_date: new Date('2019-03-12T12:00:00Z'), title: 'Reldesk v0.3.5', description: '<p>Lots of internal improvements for new features coming soon.</p>' },
			{ update_date: new Date('2019-01-18T12:00:00Z'), title: 'Reldesk v0.3.0', description: '<p>See estimated revenue in Opportunities, improvements to imports and<br/>new client acquisition graph for Premium accounts.</p>' },
			{ update_date: new Date('2018-10-31T12:00:00Z'), title: 'Reldesk v0.2.6', description: '<p>All users can now create tasks via email. More user interface<br/>improvements, better contacts imports and tighter website integration.</p>' },
			{ update_date: new Date('2018-07-04T12:00:00Z'), title: 'Reldesk v0.2.0', description: '<p><code>Accelerate</code> users are now able to create Custom Fields for Contacts.<br/>Plus more user interface improvements</p>' },
			{ update_date: new Date('2018-05-16T12:00:00Z'), title: 'Reldesk v0.1.8', description: '<p>Added Subscriptions option for <code>Premium</code> users.<br/><i className="fa fa-fw fa-slack"></i>Slack notifications for everyone.</p>' },
			{ update_date: new Date('2018-02-25T12:00:00Z'), title: 'Reldesk v0.1.5', description: '<p>Dashboard & Opportunities improvements.</p>' },
			{ update_date: new Date('2017-04-15T12:00:00Z'), title: 'Reldesk v0.1.0', description: '<p>Our <code>Growing</code> plan is now in beta (welcome to the<br/>beta testers!).</p>' },
		],
		inProgress: false,
		unreadNotifications: false,
		prefsStandard: {},
	};


	componentDidMount() {
		const prefsStandard = Prefs.getUserPref("standard");

		var lastClicked = new Date(getSafe(() => prefsStandard.last_read_notifications))  //|| new Date('2018-001-01T12:00:00Z');

		var unreadItems = false;
		this.state.news.map((item) => {
			if (lastClicked > item.update_date) {}
			else unreadItems = true;
		});
		this.setState({ unreadNotifications: unreadItems, prefsStandard: prefsStandard });
	}


	handleNotificationsClick = () => {
		this.updateLastClickedNotifications();
	}

	updateLastClickedNotifications = async () => {
		var newPrefs = Object.assign({}, this.state.prefsStandard);
		newPrefs.last_read_notifications = Date();
		const ret = await Prefs.updateUserPrefs('standard', newPrefs);
		this.setState({ prefsStandard: newPrefs });
	}



	render() {
		return (
			<div>
				<a className="nav-link icon-bell" href="#" id="" data-toggle="dropdown"  onClick={this.handleNotificationsClick}>
					<i className="fa fa-bell"></i>
					{ this.state.unreadNotifications && <span className="notifications-count"></span> }
				</a>
				
				<div className="dropdown-menu dropdown-menu-right dropdown-menu-news">
					<p className="news-header pt-2 "><strong>Updates &amp; Notifications</strong></p>
					<hr/>
					<div style={{overflowY: 'scroll', maxHeight: `240px`}}>
						<NotificationsWidget news={this.state.news}/>
					</div>
				</div>
			</div>
		)
	}
}

export default NotificationsMenuItem;
