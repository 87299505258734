import { Client } from '../Client';
import { teamPreferences } from '../data/TeamPreferenceContext';


class ServerPrefs {

	getUserPref(key) {
		var prefs = Client.getUserPreferences();
		// console.log(prefs);
		// console.log("KEY", prefs[key]);
		if (prefs && prefs[key]) return prefs[key];

	}

	get(key) {
		var prefs = Client.getTeamPreferences();
		// console.log("KEY", prefs[key]);
		if (prefs && prefs[key]) return prefs[key];
	}

	getContactTypes() {
		var prefs = Client.getTeamPreferences();
		// console.log("Client Prefs: ", prefs);
		// console.log("Standard Prefs: ", teamPreferences);
		if (prefs && prefs.contact_types) return prefs.contact_types;
		else return teamPreferences.contact_types;		
	}

	getOrganisationTypes() {
		var prefs = Client.getTeamPreferences();
		if (prefs && prefs.organisation_types) return prefs.organisation_types;
		else return teamPreferences.organisation_types;		
	}

	getOpportunityStages() {
		var prefs = Client.getTeamPreferences();
		// console.log("Client Prefs: ", prefs);
		// console.log("Standard Prefs: ", teamPreferences);
		if (prefs && prefs.opportunity_stages) return prefs.opportunity_stages;
		else return teamPreferences.opportunity_stages;
	}

	getCustomFields() {
		var prefs = Client.getCustomFields();
		return prefs;
	}


	async updateUserPrefs(key, newPrefs) {
		var prefs = Client.getUserPreferences();
		if (!prefs) prefs = {}
		prefs[key] = newPrefs;
		return Client.put('/api/settings/user', prefs, (res) => {
			if (res.success == true) {
				// Potentially update the local storage user prefs (in auth) here.
				return true;
			}
			else return false;
		});
	}



}

export const Prefs = new ServerPrefs();
