import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import ScrollMemory from 'react-router-scroll-memory';

import App from './App';

import './index.css';

ReactDOM.render(
	<Router>
		<div>
			<ScrollMemory />
			<App />
		</div>
	</Router>,
	document.getElementById('root')
);
