import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import { Client } from '../../Client';
import Spinner from '../Spinner/Spinner';
import { Link } from 'react-router-dom';
// import Cookies from 'universal-cookie';
import './CheckoutForm.css';
import { CardElement, injectStripe } from 'react-stripe-elements';
var config = require('../../config');


class NewSignupForm extends Component {
	state = {
		inProgress: false,
		fields: {
			email: '',
			password: '',
			firstname: '',
			lastname: '',
			company: '',
		},
		hasErrors: false,
		formErrors: {
			email: '',
			password: '',
			firstname: '',
			lastname: '',
			company: '',
		},
	};



	performRegistration = (e) => {
		e.preventDefault();
		this.setState({ inProgress: true, hasErrors: false, formErrors: {} });
		//
		//	4000008260000000	tok_gb	United Kingdom (GB)	Visa
		//	4000058260000005	tok_gb_debit	United Kingdom (GB)	Visa (debit)
		// Fail: Expired 4000000000000069	
		// Fail: 4000000000000119

		var data = {
			plan: this.props.plan,
			plan_id: this.props.plan_period_id,
			email: this.state.fields.email, 
			password: this.state.fields.password,
			firstname: this.state.fields.firstname,
			lastname: this.state.fields.lastname,
			redirectLink: config.domain + '/account/activation',
		}
		const fullname = data.firstname + ' ' + data.lastname;
		this.props.stripe.createToken({name: fullname}).then(({token, err}) => {
			if (err) {
				console.log("Error");
				console.log(err);
			}
			else {
				if (token) {
					data.paymentToken = token.id;	// Stripe returned payment token
					// console.log("OK", data.paymentToken);
					Client.register(data).then((res) => {
						// console.log("Register result: ", res);
						if (res.success === false) {
							var formErrors = {};
							formErrors[res.field] = res.message;
							console.log("Erro", formErrors);
							this.setState({ hasErrors: true, formErrors: formErrors, inProgress: false });			
						}
						else {
							this.setState({ inProgress: false });
							if (this.props.onSuccess) this.props.onSuccess();
						}
					}).catch((err) => {
						console.log(err);
						if (err.status === 'Bad Request') alert("Error");
						this.setState({ inProgress: false });			
					});
				}
				else {
					console.log("Not ok");
				}
			}
			this.setState({ inProgress: false });
		});
	}


	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}


	handleCardChange = (change) => {
		var formErrors = {};
		if (change.error) {
			formErrors['card'] = change.error.message;
			this.setState({ hasErrors: true, formErrors: formErrors, inProgress: false });
		}
		else {
			this.setState({ hasErrors: false, formErrors: formErrors, inProgress: false });
		}
	};


	render() {
		return (
			<div>
				{ this.state.inProgress ? (<Spinner/>) : ('') }

				<form onSubmit={this.performRegistration} className="checkout-form">
					{ this.state.hasErrors ? (
						<div className="row">
							<div className="col-md-10 offset-md-1">
								<div className="bd-callout bd-callout-danger">
									<h5>There were errors creating your account</h5>
									<p>Correct the issues listed below to continue</p>
								</div>
							</div>
						</div>
					) : ('') }
					<div className="row">
						<div className="col-md-4 offset-md-2">
							<label htmlFor="inputFirstname" className="col-form-label">Firstname</label>
							<input type="text" name="firstname" className="form-control" id="inputFirstname" placeholder="" value={this.state.fields.firstname} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.firstname ? ( <span className="text-danger">{this.state.formErrors.firstname}</span>) : ('')}
						</div>
						<div className="col-md-4">
							<label htmlFor="inputLastname" className="col-form-label">Lastname</label>
							<input type="text" name="lastname" className="form-control" id="inputLastname" placeholder="" value={this.state.fields.lastname} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.lastname ? ( <span className="text-danger">{this.state.formErrors.lastname}</span>) : ('')}
						</div>
					</div>

					<div className="row">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputEmail" className="col-form-label">Work Email</label>
							<input type="text" name="email" className="form-control" id="inputEmail" placeholder="you@company.com" value={this.state.fields.email} onChange={this.handleFieldChange}/>
							{ this.state.formErrors.email ? ( <span className="text-danger">{this.state.formErrors.email}</span>) : ('')}
						</div>
					</div>
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<label htmlFor="inputPassword" className="col-form-label">Password</label>
							<input type="password" name="password" className="form-control" id="inputPassword" placeholder="" value={this.state.fields.password} onChange={this.handleFieldChange}/>
							<p className="small">Minimum 8 characters and at least 1 number</p>
							{ this.state.formErrors.password ? ( <span className="text-danger">{this.state.formErrors.password}</span>) : ('')}
						</div>
					</div>


					<div className="row">
						<div className="col-md-8 offset-md-2">
							<div>
								<label htmlFor="inputTitle" className="col-form-label">Payment Details</label>
								<CardElement onChange={this.handleCardChange}/>
								{ this.state.formErrors.card ? ( <span className="text-danger">{this.state.formErrors.card}</span>) : ('')}

								{ this.props.coupon ? (
									<div>
										Coupon code <strong>{ this.props.coupon }</strong> will be applied on signup.
									</div>
								) : ('') }

								<div className="col-md-8 offset-md-2" style={{marginTop: '30px', marginBottom: '20px'}}>
									<button >Signup</button>
								</div>
							</div>
						</div>
					</div>

				</form>
			</div>
		);
		
	}
}

// export default RegisterSignupForm;
export default injectStripe(NewSignupForm);

