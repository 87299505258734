import config from './config';
import React from 'react';
import request from 'superagent';
import { toast } from 'react-toastify';
import { getSafe } from './lib/helper';

const useLocalStorage = true;
const LOCAL_STORAGE_KEY = 'reldesk-token';

const ToastError = (props) => (
<div>
	<i className="fa fa-exclamation-triangle" style={{color: '#d04f4b'}}></i>
	<div className="" style={{color: '#d04f4b'}}>{props.message}</div>
</div>
)

const ToastInfo = (props) => (
<div>
	<i className="fa fa-info-circle" style={{color: '#4ba0d0'}}></i>
	<span className="" style={{paddingLeft: 10, color: '#4ba0d0'}}>{props.title}</span>
	<div className="" style={{color: '#4ba0d0'}}>{props.message}</div>
</div>
)


class APIClient {
	auth = null;

	constructor() {
		// this.removeToken();
		this.useLocalStorage = (typeof localStorage !== 'undefined');
		this.subscribers = [];

		if (this.useLocalStorage) {
			this.auth = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

			if (this.auth) {
				this.isTokenValid().then((bool) => {
					if (!bool) this.auth = null;
				});
			}
		}
	}

	login(email, password) {
		var data = {
			email: email,
			password: password,
		}

		return fetch(config.api.server + '/api/auth/login', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then((json) => {
				// console.log("JSON", json);
				this.setAuthResponse(json);
				return json;
			})
			.catch(function(err) {
				toast(<ToastError message={"Error: A Network error occurred. Please ensure you're online"} />);
    		});
	}


	register(data, cb) {
		return fetch(config.api.server + '/api/auth/register', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then((json) => {
				if (json.token)	this.setAuthResponse(json);
				// console.log("JSON", json);
				return json;
			})
			.catch((err) => ({err}));
	}

	register2(data, cb) {
		return fetch(config.api.server + '/api/auth/register2', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then((json) => {
				if (json.token)	this.setAuthResponse(json);
				return json;
			})
			.catch((err) => ({err}));
	}



	logout() {
		this.removeToken();
		// window.location.reload();
		window.location.href = "/";
	}

	loginToken(token) {
		return fetch(config.api.server + '/api/auth/logintoken', {
			method: 'post',
			body: JSON.stringify({token}),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then((json) => {
				this.setAuthResponse(json);
				return json;
			})
			.catch(function(err) {
				toast(<ToastError message={"Error: A Network error occurred. Please ensure you're online"} />);
    		});
	}



	// Generic 'get' request for simple calls
	get(endpoint, success) {
		return fetch(config.api.server + endpoint, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(success);
	}

	// Generic 'post' request for simple calls
	post(endpoint, data, cb) {
		return fetch(config.api.server + endpoint, {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	// Generic 'put' request for simple calls
	put(endpoint, data, cb) {
		return fetch(config.api.server + endpoint, {
			method: 'put',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	// Generic 'delete' request for simple calls
	delete(endpoint, data, cb) {
		return fetch(config.api.server + endpoint, {
			method: 'delete',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	getContacts(cb) {
		return fetch(config.api.server + '/api/crm/contacts', {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	getContact(id, cb) {
		return fetch(config.api.server + '/api/crm/contacts/' + id, {
			method: 'get',
			// body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	createContact(data, cb) {
		// console.log(data);
		return fetch(config.api.server + '/api/crm/contacts', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	updateContact(data, cb) {
		return fetch(config.api.server + '/api/crm/contacts', {
			method: 'put',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	deleteContact(data, cb) {
		return fetch(config.api.server + '/api/crm/contacts', {
			method: 'delete',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	//----


	getContactNotes(id, cb) {
		return fetch(config.api.server + '/api/crm/notes/' + id, {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	deleteContactNote(data) {
		// console.log("Deleting", data);
		return fetch(config.api.server + '/api/crm/notes/contact', {
			method: 'delete',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
		  },
		}).then(this.checkStatus);
	}

	//----

	getIntelEvents(id, cb) {
		return fetch(config.api.server + '/api/crm/intelevents', {
			headers: {
				Accept: 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	//----

	emailFindCount(domain, cb) {
		return fetch(config.api.server + '/api/emailfind/count/' + domain, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	emailFindGetEmails(domain, cb) {
		return fetch(config.api.server + '/api/emailfind/bydomain/' + domain, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}



	//----


	getProfile(cb) {
		return fetch(config.api.server + '/api/user/profile', {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	updateProfile(data, cb) {
		return fetch(config.api.server + '/api/user/profile', {
			method: 'put',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}




	uploadCSV(type, file, cb) {
		if (!file) return;
		let upload = request.post(config.api.server + '/api/crm/contacts/importcsv2')
			.field('file', file)
			.field('type', type)
			.set('Authorization', 'Bearer ' + this.getToken());
			
			//Slightly different due to using Superagent
			upload.then(this.checkStatus)
			.then((res) => {
				return res.body;
			})
			.then(cb);
	}


	getStatsContactsDistribution(data, cb) {
		return fetch(config.api.server + '/api/crm/stats/contactsdistribution', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}

	// ------

	emailSend(data, cb) {
		return fetch(config.api.server + '/api/crm/email/send', {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.getToken(),
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then(cb);
	}


	// ------
	setAuthResponse(data) {
		// console.log("Saving User", data);
		this.auth = data;
		if (useLocalStorage) localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
	}


	removeToken() {
		if (this.auth) this.auth.token = null;
		if (useLocalStorage) localStorage.removeItem(LOCAL_STORAGE_KEY);
		// console.log("Token now ", this.auth.token);
	}

	getToken() {
		if (this.auth) return this.auth.token;
		else return null;
		// return this.auth.token;
	}

	getTeamPreferences() {
		// console.log("Prefs", this.auth);
		if (this.auth) return this.auth.team_preferences;
		else return null;
	}

	getUserPreferences() {
		if (this.auth) return this.auth.user_preferences;
		else return null;
	}

	getCustomFields() {
		if (this.auth) return this.auth.custom_fields;
		else return null;
	}


	showToast(message, type) {
		if (type === "error") toast(<ToastError message={message} />);
		else toast(<ToastInfo message={message} />);
	}

	isTokenValid() {
		// console.log("Checking token is valid ", this.auth.token);
		return fetch(config.api.server + '/api/auth/token/' + this.auth.token, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(this.checkStatus)
			.then(this.parseJSON)
			.then((json) => json.valid === true)
			.catch(function(err) {
				toast(<ToastError message={"Error: A Network error occurred. Please ensure you're online and logged in"} />);
    		});
	}


	checkStatus = (response) => {
		if (response.status >= 200 && response.status < 300) {
			return response;
		} else {

			// If we receive an unauthorised response, logout the user
			// (this should be changed in future when separate products are added which may result in unauthorised response but should
			// still remain logged in. Return a 403 for access control errors, 401 for not authed at all
			// console.log(response.status);
			if (response.status === 401) {
				this.logout();
				return response;
				// throw new UnauthorisedException("You are not authenticated");
			}
			else if (response.status === 400) {
				response.clone().json().then(json => {
					var code = json.code ? '#' + json.code : '#0000'
					var message = json.message ? json.message : '';
					toast(<ToastError message={"Error " + code + " : " + message} />);
				});

				return response;
			}
			else if (response.status === 409) {
				return response;
			}
			else {
			// response.json().then(function(data) {  
				// console.log("DATA", data); 
				// console.log(response);
				// console.log(response.json());
				const error = new Error(`HTTP Error ${response.statusText}`);
				// const error = new Error(`HTTP Error`);
				error.status = response.statusText;
				error.response = response;
				// toast("Error: " + response.statusText);
				toast(<ToastError message={"Error: " + response.statusText} />);

				// error.data = data;
				// error.data = data;
				// // console.log("Error ", error);
				throw error;
			// });
			}

		}
	}

	parseJSON(response) {
		return response.json();
	}


	isLoggedIn() {
		if (!this.auth) return false;
		//console.log(this.auth);		
		return !!this.auth.token;
		// if (this.auth) return !!this.auth.token;
		// else return false;
	}

	isNewUser() {
		// TODO
		return false;
	}

	getFirstname() {
		if (this.auth) return this.auth.firstname;
	}

	getLastname() {
		if (this.auth) return this.auth.lastname;
	}

	getTeamId() {
		if (this.auth) return this.auth.team_id;
	}

	hasRole(validRoles) {
		if (this.auth) return validRoles.some(item => this.auth.role === item);
		else return false;
	}

	uid() {
		if (this.auth) return this.auth.uid;
		else return false;
	}

	isAdmin() {
		if (this.auth) return this.auth.is_team_admin;
		else return false;
	}

	role() {
		if (this.auth) return this.auth.role;	// Change to package
		else return false;
	}

	hasAdditionalFeature(feature) {
		return getSafe(() => this.auth.additional_features.includes(feature));
	}

}

export const Client = new APIClient();


// function UnauthorisedException(message) {
// 	this.message = message;
// 	this.name = 'UnauthorisedException';
// }









