import React, { Component } from 'react';
import { Client } from '../../Client';
import Spinner from '../Spinner/Spinner';
var config = require('../../config');


class PasswordResetEmail extends Component {
	state = {
		inProgress: false,
	    fields: {
			email: '',
	    },
		hasErrors: false,
		hasMessage: false,
		formError: '',
		formMessage: ''
	};

	sendResetEmail = (e) => {
		e.preventDefault();
		this.setState({ inProgress: true });
		var errMessage = '';

		Client.post('/api/auth/password/reset', { redirectLink: config.domain + '/password/reset', email: this.state.fields.email }, (res) => {
			if (res.success === false) this.setState({ hasErrors: true, formError: 'Problem sending reset email', inProgress: false });
			else {
				var formMessage = "If you have a valid account you will shortly receive a password reset email. ";
				//If you don't receive an email in 15 minutes, please check your spam folder and ensure your account email address is correct.
				this.setState({ hasErrors: false, hasMessage: true	, formMessage: formMessage, inProgress: false });				
			}
		}).catch((err) => {
			if (err.message) errMessage = <div><h5>{err.message}</h5></div>;
			this.setState({ hasErrors: true, hasMessage: false, formError: errMessage, inProgress: false });			
		});
	};

	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	};


	render() {
		return (
			<div>
				{ this.state.inProgress ? (<Spinner/>) : ('') }

				<div className="row">
					<div className="col-md-6 offset-md-3">

						<div className="card">
							<div className="card-block">
								<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Reset <strong>Password</strong></h4>

								<form onSubmit={this.sendResetEmail}>
									{ this.state.hasErrors ? (
										<div className="row">
											<div className="col-md-10 offset-md-1">
												<div className="bd-callout bd-callout-danger">
													{this.state.formError}
												</div>
											</div>
										</div>
									) : ('') }
									{ this.state.hasMessage ? (
										<div className="row">
											<div className="col-md-10 offset-md-1">
												<div className="bd-callout bd-callout-info">
													{this.state.formMessage}
												</div>
											</div>
										</div>
									) : ('') }
									<div className="row">
										<div className="col-md-8 offset-md-2">
											<label htmlFor="inputEmail" className="col-form-label">Email</label>
											<input type="text" name="email" className="form-control" id="inputEmail" placeholder="" value={this.state.fields.email} onChange={this.handleFieldChange}/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-8 offset-md-2" style={{marginTop: '20px', marginBottom: '20px'}}>
											<button type="submit" className="form-control btn btn-primary">
												Request Password Reset
											</button>
										</div>
									</div>

								</form>

							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default PasswordResetEmail;


