import React from 'react';
import { Link } from 'react-router-dom';
import './Avatar.css';

class Avatar extends React.Component {

	bgColor(string) {
		var finalColor;
		var seed = 0;
		// var colors = ["#1abc9c", "#16a085", "#f1c40f", "#f39c12", "#2ecc71", "#27ae60", "#e67e22", "#d35400", "#3498db", "#2980b9", "#e74c3c", "#c0392b", "#9b59b6", "#8e44ad", "#bdc3c7", "#34495e", "#2c3e50", "#95a5a6", "#7f8c8d", "#ec87bf", "#d870ad", "#f69785", "#9ba37e", "#b49255", "#b49255", "#a94136"];
		// var colors = ["#00c4a2", "#008af6", "#a9b1da", "#00ceca", "#ff7e4d", "#ffba49"];
		var colors = ['rgba(0, 196, 162, 0.5)', 'rgba(0, 138, 246, 0.5)', 'rgba(169, 177, 218, 0.5)', 'rgba(0, 206, 202, 0.5)', 'rgba(255, 126, 77, 0.5)', 'rgba(255, 186, 73, 0.5)'];

		var colorIndex = Math.floor((string.charCodeAt(0) + seed) % colors.length);
		finalColor = colors[colorIndex];
		return finalColor;
	}

	render() {
		let firstInitial = '';
		let secondInitial = '';
		if (!this.props.firstname && !this.props.lastname) firstInitial = '?';
		else if (this.props.firstname && !this.props.lastname) firstInitial = this.props.firstname.charAt(0).toUpperCase();
		else if (!this.props.firstname && this.props.lastname) firstInitial = this.props.lastname.charAt(0).toUpperCase();
		else {
			firstInitial = this.props.firstname.charAt(0).toUpperCase();
			secondInitial = this.props.lastname.charAt(0).toUpperCase();
		}

		let avatarHtml = null;
		let avatarImg = {};
		// if (this.props.image) avatarHtml = <img src={this.props.image} alt={firstInitial + ' ' + secondInitial}/>;
		let bgColor = this.bgColor(firstInitial + secondInitial);

		if (this.props.image) {
			avatarHtml = '';
			avatarImg = {backgroundImage: `url(${this.props.image})`};
		}
		else if (this.props.text) {
			avatarHtml = <span className="text">{this.props.text}</span>;
		}
		else {
			avatarHtml = <span className="initials">{firstInitial + ' ' + secondInitial}</span>;
			avatarImg = { backgroundColor: bgColor };
		}

		let statusHtml = null;
	    if (this.props.status === 'online') statusHtml = <i className="status status--online"></i>;
	    else if  (this.props.status === 'offline') statusHtml = <i className="status status--offline"></i>
	    else statusHtml = null;

		let size = '';
		if (this.props.size === 'large') size = 'Avatar-lg';
		else if (this.props.size === 'small') size = 'Avatar-sm';
		const Avatar = (
			<div className={'Avatar ' + size} style={avatarImg}>
				{avatarHtml}
				{statusHtml}
			</div>
		);

		if (this.props.link) {
			return ( 
				<Link to={this.props.link}>
					{Avatar}
				</Link>
			);
		}
		else {
			return ( 
				<span>
					{Avatar}
				</span>
			);			
		}

	}
}




export default Avatar;
