import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Client } from '../../Client';


class Logout extends Component {

	constructor(props) {
		super(props);
		Client.logout();
	}

	render() {
		return (
			<Redirect to='/login' />
		);
	}
}

export default Logout;
