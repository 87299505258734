import React from 'react';


class Footer extends React.Component {

	render() {
		return (
			<div className="container pt-3 pb-4" style={{fontSize: `0.85em`, color: '#94a8bc'}}>
				<div className="row">
					<div className="col-6">
						Copyright &copy; {new Date().getFullYear()} Reldesk
					</div>
					<div className="col-6 text-right">
						<a href="/page/privacy" className="mr-4">Privacy policy</a>
						<a href="/page/terms">Terms &amp; conditions</a>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;
