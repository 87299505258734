import React from 'react';
import './Spinner.css';

class Spinner extends React.Component {
	render() {
		if (this.props.type === 'small') return <div className={'spinner-small ' + this.props.className}></div>
		else return <div className={'Spinner ' + this.props.className}></div>
	}
}


export default Spinner;
