import React from 'react';
import './Package.css';
import renderHTML from 'react-render-html';


class Package extends React.Component {

	// constructor(props) {
	// 	super(props);
	// }


	// toggleClass() {
	// 	const currentState = this.state.active;
	// 	this.setState({ active: !currentState });
	// };

			// <div 
			// 	className={this.state.active ? 'your_className': null} 
			// 	onclick={this.toggleClass} 
			// >
			// 	<p>{this.props.text}</p>
			// </div>

	handleClick = (e, plan) => {
		console.log("CLICK", plan);
		e.preventDefault();
		if (this.props.onClick) this.props.onClick(plan);
	};


	render() {
		var currencySymbol = "£";
		var active = "";
		if (this.props.active) active = "active";

		if (this.props.display === 'minimal') {
			return (
				<div className={"package-panel package-panel-minimal " + active}>
					<img className="package-img" src={this.props.image} alt=""/>
					<h2>{this.props.title}</h2>
				</div>
			);
		}
		else {
			var cta = "";
			if (this.props.cta) cta = this.props.cta
			return (

				<a href="#" onClick={(e) => this.handleClick(e, this.props.plan)}>
					<div className={"package-panel " + active}>
						<img className="package-img" src={this.props.image} alt=""/>
						<h2>{this.props.title}</h2>
						{ this.props.children }
						<h3>{currencySymbol}{this.props.price}</h3>
						<div style={{marginBottom: 12}}>
							{ renderHTML(this.props.priceStatement) }
						</div>
						{ cta }
					</div>
				</a>
			)
		}
  }
}

export default Package;
