import React, { Component } from 'react';

class PageTerms extends Component {
	componentWillMount() {
	}


	render() {

			return (
				<div className="">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						<div className="row">
							<div className="col-md-10 offset-md-1">
								<div className="card">
									<div className="card-block">
										<h2 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Terms &amp; Conditions</h2>


										<p>
											These Terms and Conditions, together with any and all other documents
											referred to herein, set out the terms of use under which you may use
											Reldesk ("Our Web App"). Please read these Terms and Conditions carefully
											and ensure that you understand them. You will be required to read and
											accept these Terms and Conditions when signing up for an Account and
											purchasing a Subscription. If you do not agree to comply with and be bound
											by these Terms and Conditions, you must stop using Our Web App immediately.
										</p>
										<p>
											1. Definitions and Interpretation
										</p>
										<p>
											1.1 In these Terms and Conditions, unless the context otherwise requires,
											the following expressions have the following meanings:
										</p>
										<table border="0" cellspacing="0" cellpadding="0">
											<tbody>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Account"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															Means an account required to access and use Our Web App, as
															detailed in Clause 4;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Content"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means any and all text, images, audio, video, scripts,
															code, software, databases and any other form of information
															capable of being stored on a computer that appears on, or
															forms part of, Our Web App;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Contract"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means the contract between Us and you for the purchase and
															sale of a Subscription to Our Web App, as explained in
															Clause 6;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Order"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means your order for a Subscription;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Subscription Confirmation"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means Our acceptance and confirmation of your Order;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"Subscription"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means a subscription to access Our Web App, purchased in
															accordance with these Terms and Conditions;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"User"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means a user of Our Web App;
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"User Content"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means customer data created and/or uploaded by Users in or
															to Our Web App; and
														</p>
													</td>
												</tr>
												<tr>
													<td width="193" valign="top">
														<p align="left">
															<strong>"We/Us/Our"</strong>
														</p>
													</td>
													<td width="361" valign="top">
														<p align="left">
															means Distortion Ltd, a limited company registered in
															England under company number 4151921, whose registered
															address is 7 Willoughby Cl, Melton Mowbray, LE13 1HJ.
														</p>
													</td>
												</tr>
											</tbody>
										</table>
										<p>
											2. Information About Us
										</p>
										<p>
											2.1 Our Web App is owned and operated by Distortion Ltd, a limited company
											registered in England under company number 4151921, whose registered
											address is 7 Willoughby Cl, Melton Mowbray, LE13 1HJ. Our VAT number is GB
											776 0995 77.
										</p>
										<p>
											<strong></strong>
										</p>
										<p>
											3. Access and Changes to Our Web App
										</p>
										<p>
											3.1 Access to Our Web App requires a Subscription. Upon purchasing a
											Subscription, Our Web App will be available to you for the duration of that
											Subscription and any and all subsequent renewals.
										</p>
										<p>
											3.2 We may from time to time make changes to Our Web App:
										</p>
										<p>
											3.2.1 Minor changes may be required to make underlying technical
											alterations, for example, to fix an error or to address a security issue.
											We will inform you by notification within the app of any such changes
											(including, if applicable, anything that you need to do), however they will
											be unlikely to materially affect your use of Our Web App;
										</p>
										<p>
											3.2.2 Minor changes may be made to reflect changes in the law or other
											regulatory requirements. We will inform you by notification within the app
											and/or by email of any such changes (including, if applicable, anything
											that you need to do), however they will be unlikely to materially affect
											your use of Our Web App; and
										</p>
										<p>
											3.2.3 As detailed at www.reldesk.com, We may develop and improve Our Web
											App over time, in some cases making significant changes to it. You will be
											kept informed of any and all such changes.
										</p>
										<p>
											3.3 We will always aim to ensure that Our Web App is available at all
											times. In certain limited cases, however, We may need to temporarily
											suspend availability to make certain changes outlined under sub-Clause 3.2.
											Unless We are responding to an emergency or an urgent issue, We will inform
											you in advance of any interruptions to the availability of Our Web App. If
											We need to suspend Our Web App for longer than 48 hours, We will add the
											corresponding time to the duration of your current Subscription period at
											no cost to you, rounded up to a full day in each case. If We need to
											suspend Our Web App for longer than 72 hours you may also have a right to
											cancel. Please refer to sub-Clause 8.4.5 for details.
										</p>
										<p>
											4. Accounts
										</p>
										<p>
											4.1 An Account is required to use Our Web App.
										</p>
										<p>
											4.2 You may not create an Account if you are under 16 years of age.
										</p>
										<p>
											4.3 When creating an Account, the information you provide must be accurate
											and complete. If any of your information changes at a later date, it is
											your responsibility to ensure that your Account is kept up-to-date.
										</p>
										<p>
											4.4 We require that you choose a strong password for your Account,
											consisting of a combination of lowercase and uppercase letters, numbers and
											symbols. It is your responsibility to keep your password safe. You must not
											share your Account with anyone else. If you believe your Account is being
											used without your permission, please contact Us immediately. We will not be
											liable for any unauthorised use of your Account.
										</p>
										<p>
											4.5 You must not use anyone else's Account.
										</p>
										<p>
											4.6 Any personal information provided in your Account will be collected,
											used, and held in accordance with your rights and Our obligations under the
											Data Protection Act, as set out in Clause 21.
										</p>
										<p>
											4.7 If you wish to close and delete your Account, you may do so at any time
											by contacting us via www.reldesk.com. Closing your Account will result in
											the removal of your information from Our system. If you have an active
											Subscription, your Account will remain active for the duration of the
											remainder of the Subscription period you are currently in. Closing your
											Account will cancel the auto-renewal of your Subscription, where
											applicable. Closing your Account will also remove any User Content that you
											have created or uploaded from Our system.
										</p>
										<p>
											<strong></strong>
										</p>
										<p>
											5. Subscriptions, Pricing and Availability
										</p>
										<p>
											5.1 We make all reasonable efforts to ensure that all general descriptions
											of the services available from Us (specifically, Our Web App) correspond to
											the actual services that will be provided to you. There may, however, be
											minor variations due to beta testing of new features, errors, security or
											maintenance periods, from descriptions from time to time.
										</p>
										<p>
											5.2 Where appropriate, you may be required to select your required
											Subscription. Different types of Subscription provide access to different
											features in Our Web App. Please ensure that you select the appropriate
											Subscription when prompted.
										</p>
										<p>
											5.3 All pricing information is correct at the time of going online. We
											reserve the right to change prices and to add, alter, or remove special
											offers from time to time and as necessary. Changes will not affect
											Subscriptions that have already been purchased.
										</p>
										<p>
											5.4 All Subscription prices are checked by Us when your purchase is
											processed. In the unlikely event that We have shown incorrect pricing
											information, We will contact you in writing before processing your purchase
											to ask you how you wish to proceed. We will not charge you or activate your
											Subscription until you respond. If We do not receive a response from you
											within 14 days, We will treat your purchase as cancelled and notify you
											accordingly in writing.
										</p>
										<p>
											5.5 Prices shown do not include VAT and will be applied on checkout.
										</p>
										<p>
											6. Subscriptions - How Contracts Are Formed
										</p>
										<p>
											6.1 You will be guided through the Subscription process when you make a
											purchase. Before confirming a purchase, you will be given the opportunity
											to review your chosen Subscription and amend any errors in your Order.
											Please ensure that you check carefully before confirming your purchase.
										</p>
										<p>
											6.2 No part of Our Web App, website or any other material constitutes a
											contractual offer capable of acceptance. By purchasing a Subscription, you
											are making Us a contractual offer that We may, at Our sole discretion,
											accept. Our acceptance is indicated by Us sending you a Subscription
											Confirmation by email. Only once We have sent you a Subscription
											Confirmation will there be a legally binding contract between Us and you
											("the Contract").
										</p>
										<p>
											6.3 Subscription Confirmations contain the following information:
										</p>
										<p>
											6.3.1 Confirmation of your chosen Subscription including full details of
											the main characteristics and features of Our Web App available as part of
											that Subscription;
										</p>
										<p>
											6.3.2 Fully itemised pricing, including, where appropriate, taxes and other
											additional charges;
										</p>
										<p>
											6.4 If We do not accept or cannot process your Subscription purchase for
											any reason, no payment will be taken under normal circumstances. If We have
											taken payment in such circumstances, the payment will be refunded to you as
											soon as possible and in any event within 14 days.
										</p>
										<p>
											6.5 Subject to the cancellation provisions in Clause 8, once you have
											confirmed your Subscription purchase, your Subscription cannot be changed
											until the end or renewal date of that Subscription.
										</p>
										<p>
											6.6 By purchasing a Subscription, you are expressly requesting that you
											wish access to Our Web App to be made available to you immediately (and
											will be required to acknowledge this). If you are a consumer, this will
											result in your right to cancel during the "cooling-off" period being lost.
											Please be aware that We do not offer any Subscriptions that do not begin
											immediately. For more details of cancellation, please refer to Clause 8.
										</p>
										<p>
											7. Payment
										</p>
										<p>
											7.1 Payment for Subscriptions will be due at the time of purchase. Your
											chosen payment method will be billed immediately upon confirmation of your
											Subscription.
										</p>
										<p>
											7.2 We accept the following methods of payment:
										</p>
										<p>
											7.2.1 Credit Card;
										</p>
										<p>
											7.2.2 Bank Transfer;
										</p>
										<p>
											<strong></strong>
										</p>
										<p>
											8. Cancellation
										</p>
										<p>
											8.1 Consumers (but not business customers) in the European Union have a
											legal right to a "cooling-off" period within which distance sales contracts
											(including those formed online) can be cancelled for any reason. This
											period, if applicable, begins once a contract is formed and ends at the end
											of 14 calendar days after that date.
										</p>
										<p>
											8.2 Please note that, as explained in sub-Clause 6.6, because access to Our
											Web App is made available immediately upon the purchase of a Subscription,
											the 14-day cooling-off period does not apply.
										</p>
										<p>
											8.3 You may cancel at any time in the following limited circumstances and
											you may be entitled to a full or partial refund for services or digital
											content not provided:
										</p>
										<p>
											8.3.1 We have incorrectly described Our Web App or it is faulty (please
											refer to Clause 16 for more details); or
										</p>
										<p>
											8.3.2 We have informed you of an upcoming change to Our Web App or to these
											Terms and Conditions that you do not agree to; or
										</p>
										<p>
											8.3.3 We have informed you of an error in the price or description of your
											Subscription or Our Web App and you do not wish to continue; or
										</p>
										<p>
											8.3.4 There is a risk that the availability of Our Web App may be
											significantly delayed due to events outside of Our control; or
										</p>
										<p>
											8.3.5 We have informed you that We have suspended, or are planning to
											suspend, availability of Our Web App for a period greater than 72 hours; or
										</p>
										<p>
											8.3.6 We have breached these Terms and Conditions or have in any way failed
											to comply with Our legal obligations to you.
										</p>
										<p>
											8.4 Subject to sub-Clause 8.3, non-renewing Subscriptions cannot be
											cancelled. Auto-renewing Subscriptions can be cancelled at any time,
											however (also subject to sub-Clause 8.3), no refunds can be provided and
											you will continue to have access to Our Web App for the duration of the
											remainder of the Subscription period you are currently in. Cancelling an
											auto-renewing Subscription only prevents it from being auto-renewed.
										</p>
										<p>
											8.5 To cancel a Subscription for any reason, please inform us using one of
											the following methods:
										</p>
										<p>
											8.5.1 Online using Our contact form at reldesk.com.
										</p>
										<p>
											8.6 We may ask you why you have chosen to cancel your Subscription and may
											use any answers you provide to improve Our Web App in the future, however
											please note that you are under no obligation to provide any details if you
											do not wish to.
										</p>
										<p>
											8.7 Any and all refunds due to you will be made no later than 14 calendar
											days after the date on which We acknowledge your cancellation. Refunds will
											be made to your original payment method.
										</p>
										<p>
											8.8 In certain limited circumstances We may cancel your Subscription and/or
											close your Account. If We take such action, you will be notified by email
											and We will provide an explanation for the cancellation and/or closure.
										</p>
										<p>
											8.8.1 If your Account is closed and your Subscription cancelled because you
											have breached these Terms and Conditions, you will not be entitled to a
											refund. If you believe We have closed your Account and cancelled your
											Subscription in error, please contact Us at www.reldesk.com.
										</p>
										<p>
											8.8.2 If your Account is closed and/or your Subscription is cancelled for
											any other reason, you will be refunded the remaining balance of your
											Subscription. The refund will be calculated based upon the price of your
											Subscription being divided by the total number of days in the Subscription
											and multiplied by the number of whole days remaining until the end of the
											Subscription (or, in the case of auto-renewing Subscriptions, until the
											renewal date). Any and all refunds due to you will be made no later than 14
											calendar days after the date on which the closure and/or cancellation
											becomes effective. Refunds will be made to your original payment method.
										</p>
										<p>
											<strong></strong>
										</p>
										<p>
											9. Our Intellectual Property Rights and Licence
										</p>
										<p>
											9.1 We grant Users a limited, non-exclusive, revocable, worldwide,
											non-transferable licence to use Our Web App to manage their clients and
											client data for personal and business purposes, subject to these Terms and
											Conditions.
										</p>
										<p>
											9.2 Subject to the licence granted to Us under sub-Clause 12.3, Users
											retain the ownership of copyright and other intellectual property rights in
											their User Content (subject to any third party rights in that User Content
											and the terms of any licence under which you use such Content).
										</p>
										<p>
											9.3 All other Content included in Our Web App (including all user-facing
											material, and all underlying material such as code, software and databases)
											and the copyright and other intellectual property rights in that Content,
											unless specifically labelled otherwise, belongs to or has been licensed by
											Us. All Content is protected by applicable United Kingdom and international
											intellectual property laws and treaties.
										</p>
										<p>
											9.4 By accepting these Terms and Conditions, you hereby undertake:
										</p>
										<p>
											9.4.1 Not to copy, download or otherwise attempt to acquire any part of Our
											Web App;
										</p>
										<p>
											9.4.2 Not to disassemble, decompile or otherwise reverse engineer Our Web
											App;
										</p>
										<p>
											9.4.3 Not to allow or facilitate any use of Our Web App that would
											constitute a breach of these Terms and Conditions; and
										</p>
										<p>
											9.4.4 Not to embed or otherwise distribute Our Web App on any website, ftp
											server or similar.
										</p>
										<p>
											10. Links to Our Web App
										</p>
										<p>
											10.1 You may link to Our Web App is hosted provided that:
										</p>
										<p>
											10.1.1 You do so in a fair and legal manner;
										</p>
										<p>
											10.1.2 You do not do so in a manner that suggests any form of association,
											endorsement or approval on Our part where none exists;
										</p>
										<p>
											10.1.3 You do not use any of Our logos or trade marks (or any others
											displayed on Our Web App) without Our express written permission; and
										</p>
										<p>
											10.1.4 You do not do so in a way that is calculated to damage Our
											reputation or to take unfair advantage of it.
										</p>
										<p>
											10.2 You may not link to Our Web App from any other website the content of
											which contains material that:
										</p>
										<p>
											10.2.1 Is sexually explicit;
										</p>
										<p>
											10.2.2 Is obscene, deliberately offensive, hateful or otherwise
											inflammatory;
										</p>
										<p>
											10.2.3 Promotes violence;
										</p>
										<p>
											10.2.4 Promotes or assists in any form of unlawful activity;
										</p>
										<p>
											10.2.5 Discriminates against, or is in any way defamatory of, any person,
											group or class of persons, race, sex, religion, nationality, disability,
											sexual orientation, or age;
										</p>
										<p>
											10.2.6 Is designed or is otherwise likely to threaten, harass, annoy,
											alarm, inconvenience, upset, or embarrass another person;
										</p>
										<p>
											10.2.7 Is calculated or is otherwise likely to deceive another person;
										</p>
										<p>
											10.2.8 Is designed or is otherwise likely to infringe (or threaten to
											infringe) another person's privacy;
										</p>
										<p>
											10.2.9 Misleadingly impersonates any person or otherwise misrepresents the
											identity or affiliation of a particular person in a way that is calculated
											to deceive (obvious parodies are not included in this definition provided
											that they do not fall within any of the other provisions of this sub-Clause
											10.2);
										</p>
										<p>
											10.2.10 Implies any form of affiliation with Us where none exists;
										</p>
										<p>
											10.2.11 Infringes, or assists in the infringement of, the intellectual
											property rights (including, but not limited to, copyright, trade marks,
											patents and database rights) of any other party; or
										</p>
										<p>
											10.2.12 Is made in breach of any legal duty owed to a third party
											including, but not limited to, contractual duties and duties of confidence.
										</p>
										<p>
											<strong></strong>
										</p>
										<p>
											11. Links to Other Content
										</p>
										<p>
											We may provide links to other content such as websites, web apps and
											downloadable apps. Unless expressly stated, this content is not under Our
											control. We neither assume or accept responsibility or liability for such
											third party content. The provision of a link by Us is for reference only
											and does not imply any endorsement of the linked content or of those in
											control of it.
										</p>
										<p>
											12. User Content
										</p>
										<p>
											12.1 You agree that you will be solely responsible for any and all User
											Content that you create or upload using Our Web App. Specifically, you
											agree, represent and warrant that you have the right to create or upload
											the User Content and the right to use all materials of which it is
											comprised and that it will not contravene any aspect of Our Acceptable
											Usage Policy, detailed in Clause 14.
										</p>
										<p>
											12.2 You agree that you will be liable to Us and will, to the fullest
											extent permissible by law, indemnify Us for any breach of the warranties
											given by you under sub-Clause 12.1. You will be responsible for any loss or
											damage suffered by Us as a result of such breach.
										</p>
										<p>
											12.3 You (or your licensors, as appropriate) retain ownership of your User
											Content and all intellectual property rights subsisting therein. By
											creating or uploading User Content, you grant Us an unconditional,
											non-exclusive, fully transferable, royalty-free, perpetual, worldwide
											licence to use, store, archive, syndicate, publish, transmit, adapt, edit,
											reproduce, distribute, prepare derivative works from, display, perform and
											sub-licence your User Content for the purposes of operating and promoting
											Our Web App.
										</p>
										<p>
											12.4 If you wish to remove User Content, you may do so by using the
											appropriate delete button. Removing User Content also revokes the licence
											granted to Us to use that User Content under sub-Clause 12.3. You
											acknowledge, however, that caching or references to your User Content may
											not be made immediately unavailable (or may not be made unavailable at all
											where they are outside of Our reasonable control).
										</p>
										<p>
											12.5 We may reject, reclassify, or remove any User Content created or
											uploaded using Our Web App where that User Content, in Our sole opinion,
											violates Our Acceptable Usage Policy, or if We receive a complaint from a
											third party and determine that the User Content in question should be
											removed as a result.
										</p>
										<p>
											13. Intellectual Property Rights and User Content
										</p>
										<p>
											13.1 All User Content and the intellectual property rights subsisting
											therein, unless specifically labelled otherwise, belongs to or has been
											licenced by the relevant User. All User Content is protected by applicable
											United Kingdom and international intellectual property laws and treaties.
										</p>
										<p>
											13.2 Users may not copy, distribute, publicly perform, publicly display,
											reproduce or create derivative works based upon, another User's User
											Content without first obtaining the express consent of the User to whom the
											User Content in question belongs.
										</p>
										<p>
											13.3 We take technical measures to limit and/or restrict the ability of
											Users to unlawfully copy User Content created using Our Web App. Despite
											such measures, We do not make any representation or warranty that your User
											Content will not be unlawfully copied without your permission.
										</p>
										<p>
											14. Acceptable Usage Policy
										</p>
										<p>
											14.1 You may only use Our Web App in a manner that is lawful and that
											complies with the provisions of this Clause 14. Specifically:
										</p>
										<p>
											14.1.1 You must ensure that you comply fully with any and all applicable
											local, national and international laws and/or regulations;
										</p>
										<p>
											14.1.2 You must not use Our Web App in any way, or for any purpose, that is
											unlawful or fraudulent;
										</p>
										<p>
											14.1.3 You must not use Our Web App to knowingly send, upload, or in any
											other way transmit data that contains any form of virus or other malware,
											or any other code designed to adversely affect computer hardware, software
											or any data of any kind; and
										</p>
										<p>
											14.1.4 You must not use Our Web App in any way, or for any purpose, that is
											intended to harm any person or persons in any way.
										</p>
										<p>
											14.2 The following types of User Content are not permitted on Our Web App
											and you must not create, submit, communicate or otherwise do anything that:
										</p>
										<p>
											14.2.1 is obscene, deliberately offensive, hateful, or otherwise
											inflammatory;
										</p>
										<p>
											14.2.2 promotes violence;
										</p>
										<p>
											14.2.3 promotes or assists in any form of unlawful activity;
										</p>
										<p>
											14.2.4 discriminates against, or is in any way defamatory of, any person,
											group or class of persons, race, sex, religion, nationality, disability,
											sexual orientation, or age;
										</p>
										<p>
											14.2.5 is intended or otherwise likely to threaten, harass, annoy, alarm,
											inconvenience, upset, or embarrass another person;
										</p>
										<p>
											14.2.6 is calculated or otherwise likely to deceive;
										</p>
										<p>
											14.2.7 is intended or otherwise likely to infringe (or threaten to
											infringe) another person's right to privacy;
										</p>
										<p>
											14.2.8 misleadingly impersonates any person or otherwise misrepresents your
											identity or affiliation in a way that is calculated to deceive (obvious
											parodies are not included within this definition provided that they do not
											fall within any of the other provisions of this sub-Clause 14.2);
										</p>
										<p>
											14.2.9 implies any form of affiliation with Us where none exists;
										</p>
										<p>
											14.2.10 infringes, or assists in the infringement of, the intellectual
											property rights (including, but not limited to, copyright, patents, trade
											marks and database rights) of any other party; or
										</p>
										<p>
											14.2.11 is in breach of any legal duty owed to a third party including, but
											not limited to, contractual duties and duties of confidence.
										</p>
										<p>
											14.3 We reserve the right to suspend or terminate your Account and/or your
											access to Our Web App if you materially breach the provisions of this
											Clause 14 or any of the other provisions of these terms and conditions.
											Specifically, We may take one or more of the following actions:
										</p>
										<p>
											14.3.1 Suspend, whether temporarily or permanently, your Account and/or
											your right to access Our Web App (for more details regarding such
											cancellation, please refer to sub-Clause 8.8);
										</p>
										<p>
											14.3.2 Remove any of your User Content which violates this Acceptable Usage
											Policy;
										</p>
										<p>
											14.3.3 Issue you with a written warning;
										</p>
										<p>
											14.3.4 Take legal proceedings against you for reimbursement of any and all
											relevant costs on an indemnity basis resulting from your breach;
										</p>
										<p>
											14.3.5 Take further legal action against you as appropriate;
										</p>
										<p>
											14.3.6 Disclose such information to law enforcement authorities as required
											or as we deem reasonably necessary; and/or
										</p>
										<p>
											14.3.7 Any other actions which We deem reasonably appropriate (and lawful).
										</p>
										<p>
											14.4 We hereby exclude any and all liability arising out of any actions
											(including, but not limited to, those set out above) that We may take in
											response to breaches of these Terms and Conditions.
										</p>
										<p>
											15. Advertising
										</p>
										<p>
											15.1 We may feature advertising within Our Web App and We reserve the right
											to display advertising on the same page as any User Content.
										</p>
										<p>
											15.2 You agree that you will not attempt to remove or hide any advertising
											using HTML/CSS or by any other method.
										</p>
										<p>
											15.3 We are not responsible for the content of any advertising in Our Web
											App. Each advertiser is responsible for the content of their own
											advertising material. We will not be responsible for any advertising in Our
											Web App including, but not limited to, any errors, inaccuracies, or
											omissions.
										</p>
										<p>
											16. Problems with Our Web App and Consumers' Legal Rights
										</p>
										<p>
											16.1 If you have any questions or complaints regarding Our Web App, please
											contact Us using any of the methods provided on Our contact page at
											www.reldesk.com.
										</p>
										<p>
											16.2 If you are a consumer, you have certain legal rights under the
											Consumer Rights Act 2015 that may apply to Our Web App:
										</p>
										<p>
											16.2.1 Any digital content provided by Us must be as described, fit for
											purpose, and of satisfactory quality. If digital content is faulty, you may
											be entitled to a repair or replacement. If a fault cannot be remedied, or
											has not been remedied within a reasonable time and without significant
											inconvenience to you, you may be entitled to a full or partial refund. If,
											as a result of Our failure to exercise reasonable care and skill, any
											digital content of which Our Web App is comprised (that is not User
											Content) damages your device or other digital content belonging to you, you
											may be entitled to a repair or compensation.
										</p>
										<p>
											16.2.2 Any services provided by Us must be provided with reasonable care
											and skill and in compliance with information provided by Us. If We fail to
											do so, you may be entitled to require Us to repeat or otherwise fix the
											problem or, if We cannot provide such a remedy, a full or partial refund.
										</p>
										<p>
											16.2.3 For more details concerning your rights and remedies as a consumer,
											please contact your local Citizens Advice Bureau or Trading Standards
											Office.
										</p>
										<p>
											17. Disclaimers
										</p>
										<p>
											17.1 No part of Our Web App or any accompanying documentation (whether
											provided in electronic form or otherwise) constitutes advice on which you
											should rely and is provided for general information purposes only.
											Professional or specialist advice should always be sought before taking any
											action relating to its use.
										</p>
										<p>
											17.2 Subject to your legal rights if you are a consumer (as summarised
											above in Clause 16), insofar as is permitted by law, We make no
											representation, warranty, or guarantee that Our Web App will meet your
											requirements, that it will be fit for a particular purpose, that it will
											not infringe the rights of third parties, that it will be compatible with
											all software and hardware, or that it will be secure.
										</p>
										<p>
											17.3 We make reasonable efforts to ensure that the content contained within
											Our Web App is complete, accurate and up-to-date. We do not, however, make
											representations, warranties or guarantees (whether express or implied) that
											Our Web App (and the content therein) is complete, accurate or up-to-date.
										</p>
										<p>
											17.4 We are not responsible for the content or accuracy, or for any
											opinions, views, or values expressed in any User Content created or
											uploaded using Our Web App. Any such opinions, views, or values are those
											of the relevant User, and do not reflect Our opinions, views, or values in
											any way.
										</p>
										<p>
											18. Our Liability
										</p>
										<p>
											18.1 If you are a consumer, We will be liable to you for any foreseeable
											loss or damage that is caused by Us as a result of Our breach of these
											Terms and Conditions or Our failure to exercise reasonable care and skill.
											Loss or damage is foreseeable if it is either obvious that it will occur or
											was contemplated by you and Us when the Contract between us was formed.
										</p>
										<p>
											18.2 If you are a business, to the fullest extent permissible by law, We
											accept no liability for any foreseeable loss in contract, tort (including
											negligence), for breach of statutory duty, or otherwise arising out of or
											in connection with the use of (or inability to use) Our Web App or the use
											of or reliance upon any Content (whether that Content is provided by Us or
											whether it is User Content) included in Our Web App.
										</p>
										<p>
											18.3 To the fullest extent permissible by law, We accept no liability to
											consumers or businesses for loss or damage that is not foreseeable.
										</p>
										<p>
											18.4 To the fullest extent permissible by law, We exclude all
											representations, warranties, and guarantees (whether express or implied)
											that may apply to Our Web App or any Content (including User Content)
											included in Our Web App.
										</p>
										<p>
											18.5 If you are a business, We accept no liability for loss of profits,
											sales, business or revenue; loss of business opportunity, goodwill or
											reputation; loss of anticipated savings; business interruption; or for any
											indirect or consequential loss or damage.
										</p>
										<p>
											18.6 We exercise all reasonable skill and care to ensure that Our Web App
											is free from viruses and other malware. Subject to sub-Clause 16.2.1, We
											accept no liability for any loss or damage resulting from a virus or other
											malware, a distributed denial of service attack, or other harmful material
											that may adversely affect your hardware, software, data or other material
											that occurs as a result of your use of Our Web App (including the
											downloading of any Content (including User Content) from it) or any other
											website or service that We may provide a link to.
										</p>
										<p>
											18.7 We neither assume nor accept responsibility or liability arising out
											of any disruption or non-availability of Our Web App resulting from
											external causes including, but not limited to, ISP equipment failure, host
											equipment failure, communications network failure, natural events, acts of
											war, or legal restrictions and censorship.
										</p>
										<p>
											18.8 Nothing in these Terms and Conditions excludes or restricts Our
											liability in any situation where it would be unlawful for us to do so
											including fraud or fraudulent misrepresentation, for death or personal
											injury resulting from negligence, or for any other forms of liability which
											cannot be excluded or restricted by law. For full details of applicable
											consumers' legal rights, including those relating to digital content,
											please contact your local Citizens' Advice Bureau or Trading Standards
											Office.
										</p>
										<p>
											19. Viruses, Malware and Security
										</p>
										<p>
											19.1 We exercise all reasonable skill and care to ensure that Our Web App
											is secure and free from viruses and other malware. We do not, however,
											guarantee that Our Web App is secure or free from viruses or other malware
											and accept no liability in respect of the same, as detailed in sub-Clause
											18.6.
										</p>
										<p>
											19.2 You are responsible for protecting your hardware, software, data and
											other material from viruses, malware and other internet security risks.
										</p>
										<p>
											19.3 You must not deliberately introduce viruses or other malware, or any
											other material which is malicious or technologically harmful either to or
											via Our Web App.
										</p>
										<p>
											19.4 You must not attempt to gain unauthorised access to any part of Our
											Web App, the server on which Our Web App is stored, or any other server,
											computer, or database connected to Our Web App.
										</p>
										<p>
											19.5 You must not attack Our Web App by means of a denial of service
											attack, a distributed denial of service attack, or by any other means.
										</p>
										<p>
											19.6 By breaching the provisions of sub-Clauses 19.3 to 19.5 you may be
											committing a criminal offence under the Computer Misuse Act 1990. Any and
											all such breaches will be reported to the relevant law enforcement
											authorities and We will cooperate fully with those authorities by
											disclosing your identity to them. Your right to use Our Web App will cease
											immediately in the event of such a breach and, where applicable, your
											Account will be suspended and/or deleted.
										</p>
										<p>
											20. Privacy and Cookies
										</p>
										<p>
											The Use of Our Web App is also governed by Our Privacy and Cookie Policy,
											available from www.reldesk.com. This policy is<strong> </strong>
											incorporated into these Terms and Conditions by this reference.
										</p>
										<p>
											21. Data Protection
										</p>
										<p>
											21.1 All personal information that We may collect (including, but not
											limited to, your name and contact details) will be collected, used, and
											held in accordance with the provisions of the Data Protection Act 1998 and
											your rights and Our obligations under that Act.
										</p>
										<p>
											21.2 We may use your personal information to:
										</p>
										<p>
											21.2.1 Reply to any communications that you send to Us;
										</p>
										<p>
											21.2.2 Send you important notices, as detailed in Clause 22;
										</p>
										<p>
											21.2.3 Provide you with support in your use of Our Web App;
										</p>
										<p>
											21.3 We will not pass your personal information on to any third parties
											without first obtaining your express permission to do so.
										</p>
										<p>
											22. Communications from Us
										</p>
										<p>
											22.1 If you have an Account, We may from time to time send you important
											notices by email. Such notices may relate to matters including, but not
											limited to, service changes, changes to these Terms and Conditions, changes
											to Our Web App, and changes to your Account.
										</p>
										<p>
											22.2 We will never send you marketing emails of any kind without your
											express consent. If you do give such consent, you may opt out at any time.
											Any and all marketing emails sent by Us include an unsubscribe link. If you
											opt out of receiving emails from Us at any time, it may take up to 14
											business days for Us to comply with your request. During that time, you may
											continue to receive emails from Us.
										</p>
										<p>
											22.3 For questions or complaints about email communications from Us
											(including, but not limited to, marketing emails), please contact Us at
											www.reldesk.com.
										</p>
										<p>
											23. Other Important Terms
										</p>
										<p>
											23.1 We may transfer (assign) Our obligations and rights under these Terms
											and Conditions (and under the Contract, as applicable) to a third party
											(this may happen, for example, if We sell Our business). If this occurs,
											you will be informed by Us in writing. Your rights under these Terms and
											Conditions will not be affected and Our obligations under these Terms and
											Conditions will be transferred to the third party who will remain bound by
											them.
										</p>
										<p>
											23.2 You may not transfer (assign) your obligations and rights under these
											Terms and Conditions (and under the Contract, as applicable) without Our
											express written permission.
										</p>
										<p>
											23.3 The Contract is between you and Us. It is not intended to benefit any
											other person or third party in any way and no such person or party will be
											entitled to enforce any provision of these Terms and Conditions.
										</p>
										<p>
											23.4 If any of the provisions of these Terms and Conditions are found to be
											unlawful, invalid or otherwise unenforceable by any court or other
											authority, that / those provision(s) shall be deemed severed from the
											remainder of these Terms and Conditions. The remainder of these Terms and
											Conditions shall be valid and enforceable.
										</p>
										<p>
											23.5 No failure or delay by Us in exercising any of Our rights under these
											Terms and Conditions means that We have waived that right, and no waiver by
											Us of a breach of any provision of these Terms and Conditions means that We
											will waive any subsequent breach of the same or any other provision.
										</p>
										<p>
											24. Changes to these Terms and Conditions
										</p>
										<p>
											24.1 We may alter these Terms and Conditions at any time. Any such changes
											will become binding on you upon your first use of Our Web App after the
											changes have been implemented. You are therefore advised to check this page
											from time to time.
										</p>
										<p>
											24.2 In the event of any conflict between the current version of these
											Terms and Conditions and any previous version(s), the provisions current
											and in effect shall prevail unless it is expressly stated otherwise.
										</p>
										<p>
											25. Contacting Us
										</p>
										<p>
											To contact Us, please do so by using any of the methods provided on Our
											contact page at www.reldesk.com.
										</p>
										<p>
											26. Law and Jurisdiction
										</p>
										<p>
											26.1 These Terms and Conditions, the Contract, and the relationship between
											you and Us (whether contractual or otherwise) shall be governed by, and
											construed in accordance with the law of England &amp; Wales.
										</p>
										<p>
											26.2 If you are a consumer, you will benefit from any mandatory provisions
											of the law in your country of residence. Nothing in Sub-Clause 26.1 above
											takes away or reduces your rights as a consumer to rely on those
											provisions.
										</p>
										<p>
											26.3 If you are a consumer, any dispute, controversy, proceedings or claim
											between you and Us relating to these Terms and Conditions, the Contract, or
											the relationship between you and Us (whether contractual or otherwise)
											shall be subject to the jurisdiction of the courts of England, Wales,
											Scotland, or Northern Ireland, as determined by your residency.
										</p>
										<p>
											26.4 If you are a business, any disputes concerning these Terms and
											Conditions, the relationship between you and Us, or any matters arising
											therefrom or associated therewith (whether contractual or otherwise) shall
											be subject to the exclusive jurisdiction of the courts of England and
											Wales.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
}

export default PageTerms;



