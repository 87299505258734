import React, { Component } from 'react';
import config from '../../config';
// import queryString from 'query-string';
import SignupDemoForm from '../Signup/SignupDemoForm';
import { Client } from '../../Client';


class SignupDemoContainer extends Component {

	handleSuccess = () => {
		// var redirectLink;
		// if (planData.request_payment) redirectLink = planData.plan_payment_link;
		// else redirectLink = config.domain + '/account/activation';
		// window.location.href = redirectLink; 
	}


	renderDemoForm = () => {
		// if (this.props.match.params.source == 'xero') return <SignupDemoForm onSuccess={this.handleSuccess} match={this.props.match} location={this.props.location}/>
		// else return <SignupDemoForm onSuccess={this.handleSuccess} match={this.props.match} location={this.props.location}/>
		return <SignupDemoForm onSuccess={this.handleSuccess} match={this.props.match} location={this.props.location}/>
	}

	render() {

		return (
			<div className="container page-dashboard style={{ textAlign: 'center' }}">
				
				<div className="row">
					<div className="col-md-12">

						<div className="card">
							<div className="card-block mt-3">
								<h2 className="text-center" style={{marginBottom: 30, fontWeight: 'bold'}}>
									Ready to <span className="g-color--primary">save time</span> and <span className="g-color--primary">build business relationships</span>?
								</h2>

								<div className="container">
									<div className="row">
										<div className="col-md-8 offset-md-2">
											{this.renderDemoForm()}
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}

}

export default SignupDemoContainer;
