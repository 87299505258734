import React from 'react';
import { Client } from '../../Client';


class Analytics extends React.Component {
	
	componentDidMount() {
		// console.log("Analytics type: ", this.props.type);
		if (this.props.type === 'user-identify')  {
			const firstname = Client.getFirstname();
			const lastname = Client.getLastname();
			const uid = Client.uid();
			const rdpackage = Client.role();

			if (uid) {	// If logged in?
				// console.log("Analytics", Client);
				let data = {
					name: firstname + ' ' + lastname,
					package: rdpackage,
					// email: ''
				}
				// if (this.props.action != '') data.action = 'login';
				window.analytics.identify(uid, data);
			}
		}
		else if (this.props.type === 'user-registration')  {
		}
		else {

		}

	}

	render() {
		return (
			<div></div>
		);
	}
}


export default Analytics;
