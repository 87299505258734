import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import { Client } from '../../Client';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
var config = require('../../config');

//
// Fulfills two purposes:
//		- Acts as a caller client to the API route for activation 
//		- Separately allows the user to re-send the activation link
//

class AccountActivation extends Component {
	state = {
		// shouldRedirect: false,
		activityIndicator: false,
	    fields: {
			email: '',
	    },
		hasErrors: false,
		formError: '',
		hasInfo: false,
		formMessage: '',

		activationId: '',
	};


	componentDidMount() {
		if (this.props.match && this.props.match.params.id)  {
			this.setState({ activationId: this.props.match.params.id });
			this.activateAccount(this.props.match.params.id);
		}
	}


	activateAccount = (tokenId) => {
		Client.get('/api/auth/activate/' + tokenId, (res) => {
			var errMessage = '';
			var infoMessage = '';
			console.log(res);
			if (res.success === false) {
				errMessage = <h5>{res.message}</h5>;
				this.setState({ hasErrors: true, formError: errMessage, activityIndicator: false });
			}
			else {
				window.analytics.track('Account Activated');
				this.setState({ shouldRedirect: true })
				infoMessage = <div><h5>Account Activated!</h5><p>Login to begin using your Reldesk account</p></div>;
				this.setState({ hasInfo: true, formInfo: infoMessage, activityIndicator: false });
			}
		}).catch((err) => {
			this.setState({ activityIndicator: false });			
		});
	};



	sendActivation = (e) => {
		e.preventDefault();
		this.setState({ activityIndicator: true });
		Client.post('/api/auth/activate', {
			email: this.state.fields.email,
			redirectLink: config.domain + '/account/activation',
		}, (res) => {
			var errMessage = '';
			var infoMessage = '';
			if (res.success === false) {
				errMessage = <h5>{res.message}</h5>;
				this.setState({ hasErrors: true, formError: errMessage, activityIndicator: false });
			}
			else {
				infoMessage = <div><h5>Account activation email sent</h5><p>Check your email for the activation link.</p></div>;
				this.setState({ hasInfo: true, formInfo: infoMessage, activityIndicator: false });
			}
		}).catch((err) => {
			this.setState({ activityIndicator: false });			
		});
	};

	handleFieldChange = (e) => {
		const fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	};


	render() {
		if (this.state.activationId) {
			
			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						
						{ this.state.activityIndicator ? (<Spinner/>) : ('') }

						<div className="row">
							<div className="col-md-6 offset-md-3">

								<div className="card">
									<div className="card-block">
										<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Account Activation</h4>
		
										{ this.state.hasErrors ? (
											<div className="row">
												<div className="col-md-10 offset-md-1">
													<div className="bd-callout bd-callout-danger">
														{this.state.formError}
													</div>
												</div>
											</div>
										) : ('') }

										{ this.state.hasInfo ? (
											<div className="row">
												<div className="col-md-10 offset-md-1">
													<div className="bd-callout bd-callout-info">
														{this.state.formInfo}
													</div>
												</div>
											</div>
										) : ('') }

										<div className="row">
											<div className="col-md-8 offset-md-2" style={{marginTop: '20px', marginBottom: '20px'}}>
												<Link to={'/login'} className="form-control btn btn-primary">Login to get started</Link>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			);

		} else {

			return (
				<div className="bgimage">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						
						{ this.state.activityIndicator ? (<Spinner/>) : ('') }

						<div className="row">
							<div className="col-md-6 offset-md-3">

								<div className="card">
									<div className="card-block">
										<h4 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}><strong>Activate</strong> your Reldesk account</h4>
										<p className="text-center">Enter your email address to re-send your activation link</p>

										<form onSubmit={this.sendActivation}>

											{ this.state.hasErrors ? (
												<div className="row">
													<div className="col-md-10 offset-md-1">
														<div className="bd-callout bd-callout-danger">
															{this.state.formError}
														</div>
													</div>
												</div>
											) : ('') }

											{ this.state.hasInfo ? (
												<div className="row">
													<div className="col-md-10 offset-md-1">
														<div className="bd-callout bd-callout-info">
															{this.state.formInfo}
														</div>
													</div>
												</div>
											) : ('') }

											<div className="row">
												<div className="col-md-8 offset-md-2">
													<label htmlFor="inputTitle" className="col-form-label">Email</label>
													<input type="text" name="email" className="form-control" id="inputTitle" placeholder="" value={this.state.fields.email} onChange={this.handleFieldChange}/>
												</div>
											</div>
									
								
											<div className="row">
												<div className="col-md-8 offset-md-2" style={{marginTop: '20px', marginBottom: '20px'}}>
													<button type="submit" className="form-control btn btn-primary">
														Activate
													</button>
												</div>
											</div>

										</form>


									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default AccountActivation;
