import React, { Component } from 'react';

class Maintenance extends Component {

	render() {
		return (
			<div className="App">
				<div className="container text-center">
					<h2>Currently Under Maintenance</h2>
					<p>We're currently carrying out some maintenance tasks and will be back shortly</p>
				</div>
			</div>
		);
	}
}

export default Maintenance;
