import React, { Component } from 'react';

class PagePrivacy extends Component {
	componentWillMount() {
	}


	render() {

			return (
				<div className="">
					<div className="container page-dashboard style={{ textAlign: 'center' }}">
						<div className="row">
							<div className="col-md-10 offset-md-1">
								<div className="card">
									<div className="card-block">
										<h2 className="text-center" style={{marginTop: `10px`, marginBottom: `10px`}}>Privacy Policy</h2>


											<p>
												Distortion Ltd t/a Reldesk understands that your privacy is important to
												you and that you care about how your personal data is used. We and value
												the privacy of everyone who visits this website, reldesk.com (“Our Site”)
												and will only collect and use personal data in ways that are described
												here, and in a way that is consistent with our obligations and your rights
												under the law.
											</p>
											<p>
												Please read this Privacy Policy carefully and ensure that you understand
												it. Your acceptance of this Privacy Policy is deemed to occur upon your
												first use of Our Site. If you do not accept and agree with this Privacy
												Policy, you must stop using Our Site immediately.
											</p>
											<h5 className="pt-4 pb-2">
												1. Definitions and Interpretation
											</h5>
											<p>
												In this Policy the following terms shall have the following meanings:
											</p>
											<table border="0" cellSpacing="0" cellPadding="0">
												<tbody>
													<tr>
														<td width="193" valign="top">
															<p align="left">
																<strong>“Account”</strong>
															</p>
														</td>
														<td width="361" valign="top">
															<p>
																means an account required to access and/or use certain
																areas and features of Our Site;
															</p>
														</td>
													</tr>
													<tr>
														<td width="193" valign="top">
															<p align="left">
																<strong>“Cookie”</strong>
															</p>
														</td>
														<td width="361" valign="top">
															<p>
																means a small text file placed on your computer or device
																by Our Site when you visit certain parts of Our Site and/or
																when you use certain features of Our Site. Details of the
																Cookies used by Our Site are set out in Part 14, below;
															</p>
														</td>
													</tr>
													<tr>
														<td width="193" valign="top">
															<p align="left">
																<strong>“Cookie Law”</strong>
															</p>
														</td>
														<td width="361" valign="top">
															<p>
																means the relevant parts of the Privacy and Electronic
																Communications (EC Directive) Regulations 2003;
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<h5 className="pt-4 pb-2">
												2. Information About Us
											</h5>
											<p>
												Our Site is owned and operated by Distortion Ltd, a Limited company
												registered in England under company number 4151921.
											</p>
											<p>
												Registered address: 7 Willoughby Cl, Melton Mowbray, LE13 1HJ.
											</p>
											<p>
												VAT number: GB 776 0995 77.
											</p>
											<h5 className="pt-4 pb-2">
												3. What Does This Policy Cover?
											</h5>
											<p>
												This Privacy Policy applies only to your use of Our Site. Our Site may
												contain links to other websites. Please note that we have no control over
												how your data is collected, stored, or used by other websites and we advise
												you to check the privacy policies of any such websites before providing any
												data to them.
											</p>
											<h5 className="pt-4 pb-2">
												4. What Is Personal Data?
											</h5>
											<p>
												Personal data is defined by the General Data Protection Regulation (EU
												Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018
												(collectively, “the Data Protection Legislation”) as ‘any information
												relating to an identifiable person who can be directly or indirectly
												identified in particular by reference to an identifier’.
											</p>
											<p>
												Personal data is, in simpler terms, any information about you that enables
												you to be identified. Personal data covers obvious information such as your
												name and contact details, but it also covers less obvious information such
												as identification numbers, electronic location data, and other online
												identifiers.
											</p>
											<h5 className="pt-4 pb-2">
												5. What Are My Rights?
											</h5>
											<p>
												Under the Data Protection Legislation, you have the following rights, which
												we will always work to uphold:
											</p>
											<p>
												a) The right to be informed about our collection and use of your personal
												data. This Privacy Policy should tell you everything you need to know, but
												you can always contact us to find out more or to ask any questions using
												the details in Part 15.
											</p>
											<p>
												b) The right to access the personal data we hold about you. Part 13 will
												tell you how to do this.
											</p>
											<p>
												c) The right to have your personal data rectified if any of your personal
												data held by us is inaccurate or incomplete. Please contact us using the
												details in Part 15 to find out more.
											</p>
											<p>
												d) The right to be forgotten, i.e. the right to ask us to delete or
												otherwise dispose of any of your personal data that we hold. Please contact
												us using the details in Part 15 to find out more.
											</p>
											<p>
												e) The right to restrict (i.e. prevent) the processing of your personal
												data.
											</p>
											<p>
												f) The right to object to us using your personal data for a particular
												purpose or purposes.
											</p>
											<p>
												g) The right to withdraw consent. This means that, if we are relying on
												your consent as the legal basis for using your personal data, you are free
												to withdraw that consent at any time.
											</p>
											<p>
												h) The right to data portability. This means that, if you have provided
												personal data to us directly, we are using it with your consent or for the
												performance of a contract, and that data is processed using automated
												means, you can ask us for a copy of that personal data to re-use with
												another service or business in many cases.
											</p>
											<p>
												i) Rights relating to automated decision-making and profiling.
											</p>
											<p>
												For more information about our use of your personal data or exercising your
												rights as outlined above, please contact us using the details provided in
												Part 15.
											</p>
											<p>
												It is important that your personal data is kept accurate and up-to-date. If
												any of the personal data we hold about you changes, please keep us informed
												as long as we have that data.
											</p>
											<p>
												Further information about your rights can also be obtained from the
												Information Commissioner’s Office or your local Citizens Advice Bureau.
											</p>
											<p>
												If you have any cause for complaint about our use of your personal data,
												you have the right to lodge a complaint with the Information Commissioner’s
												Office. We would welcome the opportunity to resolve your concerns
												ourselves, however, so please contact us first, using the details in Part
												15.
											</p>
											<h5 className="pt-4 pb-2">
												6. What Data Do You Collect and How?
											</h5>
											<p>
												Depending upon your use of Our Site, we may collect and hold some or all of
												the personal data set out in the table below, using the methods also set
												out in the table. Please also see Part 14 for more information about our
												use of Cookies and similar technologies. We do not collect any personal
												data relating to children or data relating to criminal convictions and/or
												offences.
											</p>
											<p>
												<strong></strong>
											</p>
											<table border="1" cellSpacing="0" cellPadding="8" style={{borderColor: '#eee', border: '1px solid #eee'}}>
												<tbody>
													<tr>
														<td valign="top">
															<p>
																<strong>Data Collected</strong>
															</p>
														</td>
														<td valign="top">
															<p>
																<strong>How We Collect the Data</strong>
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Identity Information including name
															</p>
														</td>
														<td valign="top">
															<p>
																Collected directly when submitted by the user on contact or
																signup.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Contact information including address, email address,
																telephone number
															</p>
														</td>
														<td valign="top">
															<p>
																Collected directly when submitted by the user on contact or
																signup.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Business information including Business name, job title,
																profession, sector, company number.
															</p>
														</td>
														<td valign="top">
															<p>
																Collected directly when submitted by the user on contact or
																signup.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Payment information including card details, bank account
																details
															</p>
														</td>
														<td valign="top">
															<p>
																Collected directly when submitted by the user on contact or
																signup.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Profile information including preferences, interests, login
																details, purchase history.
															</p>
														</td>
														<td valign="top">
															<p>
																Collected directly when submitted by the user on contact,
																signup or usage.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Technical information including IP address, browser,
																operating system.
															</p>
														</td>
														<td valign="top">
															<p>
																Collected upon visit to the website or app.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Data from third parties including technical information,
																contact information, profile information, social media
																information, company financial information.
															</p>
														</td>
														<td valign="top">
															<p>
																Collected during authentication and authorisation with the
																third parties when initiated by the user. Additional data
																is collected periodically through the authorised
																connection.
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<h5 className="pt-4 pb-2">
												7. How Do You Use My Personal Data?
											</h5>
											<p>
												Under the Data Protection Legislation, we must always have a lawful basis
												for using personal data. The following table describes how we may use your
												personal data, and our lawful bases for doing so:
											</p>
											<table border="1" cellSpacing="0" cellPadding="8" className="mb-4" style={{borderColor: '#eee', border: '1px solid #eee'}}>
												<tbody>
													<tr>
														<td valign="top">
															<p align="left">
																<strong>What We Do</strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																<strong>What Data We Use</strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																<strong>Our Lawful Basis</strong>
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Administering our business.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Identity, contact and business information.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Legitimate interest and contractual obligations where
																applicable.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
											Supplying our products and services to you.					<strong></strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Identity, contact and business information.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Legitimate interest and contractual obligations where
																applicable.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Managing payments for our products and services
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Identity, contact, business and payment information.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Legitimate interest and contractual obligations where
																applicable.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Communicating with you
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Contact information.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Legitimate interest and contractual obligations where
																applicable.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Supplying you with information by email or post that you
																have opted-in-to (you may opt-out at any time by email,
																post, via the website).
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Identity, contact and business information.
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Under consent to provide information and updates on our
																products/services.
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<p>
												With your permission and/or where permitted by law, we may also use your
												personal data for marketing purposes, which may include contacting you by
												email, telephone, text message and post with information, news, and offers
												on our products and services. You will not be sent any unlawful marketing
												or spam. We will always work to fully protect your rights and comply with
												our obligations under the Data Protection Legislation and the Privacy and
												Electronic Communications (EC Directive) Regulations 2003, and you will
												always have the opportunity to opt-out.
											</p>
											<p>
												Third Parties (including Facebook and Google) whose content appears on Our
												Site may use third-party Cookies, as detailed below in Part 14. Please
												refer to Part 14 for more information on controlling cookies. Please note
												that we do not control the activities of such third parties, nor the data
												that they collect and use themselves, and we advise you to check the
												privacy policies of any such third parties.
											</p>
											<p>
												We use the following automated systems for carrying out certain kinds of
												decision-making or profiling. If at any point you wish to query any action
												that we take on the basis of this or wish to request ‘human intervention’
												(i.e. have someone review the action themselves, rather than relying only
												on the automated method), the Data Protection Legislation gives you the
												right to do so. Please contact us to find out more using the details in
												Part 15.
											</p>
											<p>
												· The following automated decision-making method(s) may be used:
											</p>
											<p>
												o Your engagement with our email content to ensure we are sending you
												content related to the emails you engage with and with an appropriate level
												of frequency.
											</p>
											<p>
												· The following automated profiling may take place:
											</p>
											<p>
												o Monitoring of website and app usage and interactions to improve our
												products and services, and to personalise content and future
												communications.
											</p>
											<p>
												We will only use your personal data for the purpose(s) for which it was
												originally collected unless we reasonably believe that another purpose is
												compatible with that or those original purpose(s) and need to use your
												personal data for that purpose. If we do use your personal data in this way
												and you wish us to explain how the new purpose is compatible with the
												original, please contact us using the details in Part 15.
											</p>
											<p>
												If we need to use your personal data for a purpose that is unrelated to, or
												incompatible with, the purpose(s) for which it was originally collected, we
												will inform you and explain the legal basis which allows us to do so.
											</p>
											<p>
												In some circumstances, where permitted or required by law, we may process
												your personal data without your knowledge or consent. This will only be
												done within the bounds of the Data Protection Legislation and your legal
												rights.
											</p>
											<h5 className="pt-4 pb-2">
												8. How Long Will You Keep My Personal Data?
											</h5>
											<p>
												We will not keep your personal data for any longer than is necessary in
												light of the reason(s) for which it was first collected. Your personal data
												will therefore be kept for the following periods (or, where there is no
												fixed period, the following factors will be used to determine how long it
												is kept):
											</p>
											<table border="1" cellSpacing="0" cellPadding="8" className="mb-4" style={{borderColor: '#eee', border: '1px solid #eee'}}>
												<tbody>
													<tr>
														<td valign="top">
															<p>
																<strong>Type of Data</strong>
															</p>
														</td>
														<td valign="top">
															<p>
																<strong>How Long We Keep It</strong>
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Identity Information including name
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Contact information including address, email address,
																telephone number
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Business information including Business name, job title,
																profession, sector, company number.
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Payment information including card details, bank account
																details
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Profile information including preferences, interests, login
																details, purchase history.
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Technical information including IP address, browser,
																operating system.
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p>
																Data from third parties including technical information,
																contact information, profile information, social media
																information, company financial information.
															</p>
														</td>
														<td valign="top">
															<p>
																As long as is required to provide our services. Reviewed
																annually.
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<h5 className="pt-4 pb-2">
												9. How and Where Do You Store or Transfer My Personal Data?
											</h5>
											<p>
												We will store or transfer some of your personal data within the UK. This
												means that it will be fully protected under the Data Protection
												Legislation.
											</p>
											<p>
												We will store or transfer some of your personal data within the European
												Economic Area (the “EEA”). The EEA consists of all EU member states, plus
												Norway, Iceland, and Liechtenstein. This means that your personal data will
												be fully protected under the Data Protection Legislation, GDPR, and/or to
												equivalent standards by law.
											</p>
											<p>
												We may store or transfer some or all of your personal data in countries
												that are not part of the European Economic Area (the “EEA” consists of all
												EU member states, plus Norway, Iceland, and Liechtenstein). These are known
												as “third countries” and may not have data protection laws that are as
												strong as those in the UK and/or the EEA. This means that we will take
												additional steps in order to ensure that your personal data is treated just
												as safely and securely as it would be within the UK and under the Data
												Protection Legislation as follows:
											</p>
											<p>
												We transfer your personal data to third countries whose levels of data
												protection are deemed ‘adequate’ by the European Commission. More
												information is available from the <a
													href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
												target="_blank" rel="noopener noreferrer">
													European Commission
												</a>
												.
											</p>
											<p>
												Where we transfer your data to a third party based in the US, the data may
												be protected if they are part of the EU-US Privacy Shield. This requires
												that third party to provide data protection to standards similar to those
												in Europe. More information is available from the <a
													href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"
												target="_blank" rel="noopener noreferrer">
													European Commission
												</a>
												.
											</p>
											<p>
												Please contact us using the details below in Part 15 for further
												information about the particular data protection mechanisms used by us when
												transferring your personal data to a third country.
											</p>
											<p>
												The security of your personal data is essential to us, and to protect your
												data, we take a number of important measures, including the following:
											</p>
											<ul>
											<li>
												limiting access to your personal data to those employees, agents,
												contractors, and other third parties with a legitimate need to know and
												ensuring that they are subject to duties of confidentiality;
											</li>
											<li>
												procedures for dealing with data breaches (the accidental or unlawful
												destruction, loss, alteration, unauthorised disclosure of, or access to,
												your personal data) including notifying you and/or the Information
												Commissioner’s Office where we are legally required to do so;
											</li>
											<li>
												Only storing personal data in password protected or secure locations;
											</li>
											<li>
												Using SSL/TLS to ensure data passed between our web server and browsers
												remains secure;
											</li>
											</ul>
											<h5 className="pt-4 pb-2">
												10. Do You Share My Personal Data?
											</h5>
											<p>
													We may sometimes contract with the following third parties to supply
													certain products or service.
											</p>
											<table border="1" cellSpacing="0" cellPadding="8" className="mb-4" style={{borderColor: '#eee', border: '1px solid #eee'}}>
												<tbody>
													<tr>
														<td valign="top">
															<p align="left">
																<strong>Recipient</strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																<strong>Activity Carried Out</strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																<strong>Sector</strong>
															</p>
														</td>
														<td valign="top">
															<p align="left">
																<strong>Location</strong>
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Stripe
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Payment services
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Finance
															</p>
														</td>
														<td valign="top">
															<p align="left">
																United States of America
															</p>
														</td>
													</tr>
													<tr>
														<td valign="top">
															<p align="left">
																Google
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Email, file storage
															</p>
														</td>
														<td valign="top">
															<p align="left">
																Cloud
															</p>
														</td>
														<td valign="top">
															<p align="left">
																United States of America
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<p>
												If any of your personal data is shared with a third party, as described
												above, we will take steps to ensure that your personal data is handled
												safely, securely, and in accordance with your rights, our obligations, and
												the third party’s obligations under the law, as described above in Part 9.
											</p>
											<p>
												If we sell, transfer, or merge parts of our business or assets, your
												personal data may be transferred to a third party. Any new owner of our
												business may continue to use your personal data in the same way(s) that we
												have used it, as specified in this Privacy Policy.
											</p>
											<p>
												In some limited circumstances, we may be legally required to share certain
												personal data, which might include yours, if we are involved in legal
												proceedings or complying with legal obligations, a court order, or the
												instructions of a government authority.
											</p>
											<h5 className="pt-4 pb-2">
												11. How Can I Control My Personal Data?
											</h5>
											<p>
												11.1 In addition to your rights under the Data Protection Legislation, set
												out in Part 5, when you submit personal data via Our Site, you may be given
												options to restrict our use of your personal data. In particular, we aim to
												give you strong controls on our use of your data for direct marketing
												purposes (including the ability to opt-out of receiving emails from us
												which you may do by unsubscribing using the links provided in our emails or
												at the point of providing your details).
											</p>
											<p>
												11.2 You may also wish to sign up to one or more of the preference services
												operating in the UK: The Telephone Preference Service (“the TPS”), the
												Corporate Telephone Preference Service (“the CTPS”), and the Mailing
												Preference Service (“the MPS”). These may help to prevent you receiving
												unsolicited marketing. Please note, however, that these services will not
												prevent you from receiving marketing communications that you have consented
												to receiving.
											</p>
											<h5 className="pt-4 pb-2">
												12. Can I Withhold Information?
											</h5>
											<p>
												You may access certain areas of Our Site without providing any personal
												data at all. However, to use all features and functions available on Our
												Site you may be required to submit or allow for the collection of certain
												data.
											</p>
											<p>
												You may restrict our use of Cookies. For more information, see Part 14.
											</p>
											<h5 className="pt-4 pb-2">
												13. How Can I Access My Personal Data?
											</h5>
											<p>
												If you want to know what personal data we have about you, you can ask us
												for details of that personal data and for a copy of it (where any such
												personal data is held). This is known as a “subject access request”.
											</p>
											<p>
												All subject access requests should be made in writing and sent to the email
												or postal addresses shown in Part 15.
											</p>
											<p>
												There is not normally any charge for a subject access request. If your
												request is ‘manifestly unfounded or excessive’ (for example, if you make
												repetitive requests) a fee may be charged to cover our administrative costs
												in responding.
											</p>
											<p>
												We will respond to your subject access request within 28 days and, in any
												case, not more than one month of receiving it. Normally, we aim to provide
												a complete response, including a copy of your personal data within that
												time. In some cases, however, particularly if your request is more complex,
												more time may be required up to a maximum of three months from the date we
												receive your request. You will be kept fully informed of our progress.
											</p>
											<h5 className="pt-4 pb-2">
												14. How Do You Use Cookies?
											</h5>
											<p>
												Our Site may place and access certain first-party Cookies on your computer
												or device. First-party Cookies are those placed directly by us and are used
												only by us. We use Cookies to facilitate and improve your experience of Our
												Site and to provide and improve our products and services. We have
												carefully chosen these Cookies and have taken steps to ensure that your
												privacy and personal data is protected and respected at all times.
											</p>
											<p>
												By using Our Site, you may also receive certain third-party Cookies on your
												computer or device. Third-party Cookies are those placed by websites,
												services, and/or parties other than us. Third-party Cookies are used on Our
												Site for playback of videos, advertising tracking and website analytics.
												These Cookies are not integral to the functioning of Our Site and your use
												and experience of Our Site will not be impaired by refusing consent to
												them.
											</p>
											<p>
												All Cookies used by and on Our Site are used in accordance with current
												Cookie Law.
											</p>
											<p>
												Before Cookies are placed on your computer or device, you will be shown a
												popup message requesting your consent to set those Cookies. By giving your
												consent to the placing of Cookies you are enabling us to provide the best
												possible experience and service to you. You may, if you wish, deny consent
												to the placing of Cookies; however certain features of Our Site may not
												function fully or as intended.
											</p>
											<p>
												Certain features of Our Site depend on Cookies to function. Cookie Law
												deems these Cookies to be “strictly necessary”. You may still block these
												Cookies by changing your internet browser’s settings as detailed below, but
												please be aware that Our Site may not work properly if you do so. We have
												taken great care to ensure that your privacy is not at risk by allowing
												them.
											</p>
											<p>
												Our Site uses analytics services provided by Segment and Google. Website
												analytics refers to a set of tools used to collect and analyse anonymous
												usage information, enabling us to better understand how Our Site is used.
												This, in turn, enables us to improve Our Site and the products and services
												offered through it.
											</p>
											<p>
												The analytics service(s) used by Our Site use(s) Cookies to gather the
												required information. You do not have to allow us to use these Cookies,
												however whilst our use of them does not pose any risk to your privacy or
												your safe use of Our Site, it does enable us to continually improve Our
												Site, making it a better and more useful experience for you.
											</p>
											<p>
												In addition to the controls that we provide, you can choose to enable or
												disable Cookies in your internet browser. Most internet browsers also
												enable you to choose whether you wish to disable all Cookies or only
												third-party Cookies. By default, most internet browsers accept Cookies, but
												this can be changed. For further details, please consult the help menu in
												your internet browser or the documentation that came with your device.
											</p>
											<p>
												You can choose to delete Cookies on your computer or device at any time,
												however you may lose any information that enables you to access Our Site
												more quickly and efficiently including, but not limited to, login and
												personalisation settings.
											</p>
											<p>
												It is recommended that you keep your internet browser and operating system
												up-to-date and that you consult the help and guidance provided by the
												developer of your internet browser and manufacturer of your computer or
											device if you are unsure about adjusting your privacy settings.	<strong></strong>
											</p>
											<h5 className="pt-4 pb-2">
												15. How Do I Contact You?
											</h5>
											<p>
												To contact us about anything to do with your personal data and data
												protection, including to make a subject access request, please use the
												following details:
											</p>
											<p>
												Email address: privacy [at] reldesk.com.
											</p>
											<h5 className="pt-4 pb-2">
												16. Changes to this Privacy Policy
											</h5>
											<p>
												We may change this Privacy Notice from time to time. This may be necessary,
												for example, if the law changes, or if we change our business in a way that
												affects personal data protection.
											</p>
											<p>
												Any changes will be posted on Our Site and you will be deemed to have
												accepted the terms of the Privacy Policy on your first use of Our Site
												following the alterations. We recommend that you check this page regularly
												to keep up-to-date. This Privacy Policy was last updated on 12/10/2018.
											</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
}

export default PagePrivacy;



